import axios from "axios";
import router from "../routes";

const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export const instance = axios.create(
  {
    baseURL: "https://clocking.ges.alibora.com/backend/api/",
    headers: {
      'X-CSRF-TOKEN': csrfToken,
      'Content-Type': 'application/json'
    },
  },
)



export default function ({
  method = 'GET',
  url = "",
  data = {},
  // isAuth = true
}) {


  return new Promise((resolve, reject) => {
    instance({
      method,
      url,
      data,
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    }).then((res) => {
      return resolve(res.data ?? res);
    }).catch((err) => {
      if (err.status == 401) {
        logoutOut()
      }
      return reject(err)
    })
  });




}
export const logoutOut = ()=> {
  instance({
    method:"POST",
    url:"logout",
    headers: {
      "Authorization": `Bearer ${localStorage.getItem("token")}`
    }
  }).catch(() => {
    localStorage.clear()
    router.push({name:"Login"})

  }).then(()=>{
    localStorage.clear()
    router.push({name:"Login"})
  })

}

