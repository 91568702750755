<template>
    <AppLayout>
        <main id="main" class="main">

            <section class="section">
                <BreadCrumb
                    :previous="[
                        { label: 'Accueil', goTo: { name: 'Dashboard' } }, { label: 'Configurations', goTo: { name: 'Dashboard' } }]"
                    title="Admin" />
                <!-- End Page Title -->
                <div class="row">




                    <div class="col-xxl-6  col-md-12">

                        <div class="card">
                            <div class="card-body" v-if="!updateForm.isUpdate">
                                <div class="card-title">Créer un compte admin</div>

                                <div v-if="createForm.isLoading"
                                    style="min-height: 50vh;flex-direction: column;align-items: center;justify-content: center;display: flex">
                                    <div class="spinner-grow text-warning" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                                <div class="alert alert-danger  fade show" role="alert"
                                    v-else-if="createForm.showMessage && !createForm.isSuccess">
                                    <h4 class="alert-heading">Erreur </h4>
                                    <p></p>
                                    <hr>
                                    <p class="mb-0">{{ createForm.message }}</p>
                                </div>
                                <form class="row g-3" @submit.prevent="handleSubmit" v-if="!createForm.isLoading">
                                    <div class="col-md-12">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" id="floatingEmail"
                                                placeholder="Your Email" required v-model="createForm.fields.matricule"
                                                :readonly="createForm.isShow" :disabled="createForm.isShow">
                                            <label for="floatingEmail">Matricule</label>
                                        </div>
                                    </div>
                                    <div class="col-md-12 ">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" id="floatingName"
                                                placeholder="Your Name" :readonly="createForm.isShow"
                                                :disabled="createForm.isShow" v-model="createForm.fields.firstname"
                                                required>
                                            <label for="floatingName">Nom</label>
                                        </div>
                                    </div>
                                    <div class="col-md-12 ">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" id="floatingName"
                                                placeholder="Your Name" :readonly="createForm.isShow"
                                                :disabled="createForm.isShow" v-model="createForm.fields.lastname"
                                                required>
                                            <label for="floatingName">Prenoms</label>
                                        </div>
                                    </div>




                                    <div class="col-md-12">
                                        <div class="form-floating">

                                            <div v-if="chantiers.isLoading" class="spinner-grow text-warning"
                                                role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>

                                            <div class="alert alert-danger  fade show" role="alert"
                                                v-else-if="chantiers.showMessage && !chantiers.isSuccess">
                                                <h4 class="alert-heading">Erreur </h4>
                                                <p></p>
                                                <hr>
                                                <p class="mb-0">{{ chantiers.message }}</p>
                                            </div>

                                            <select class="form-select" :readonly="createForm.isShow"
                                                :disabled="createForm.isShow" v-else id="floatingSelect"
                                                aria-label="Depar" v-model="createForm.fields.chantier_id">
                                                <option v-for="(dept, i) in chantiers.result" :key="i" :value="dept.id">
                                                    {{ dept.name }}</option>

                                            </select>
                                            <label for="floatingSelect">Centre de coût</label>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-floating">

                                            <div v-if="departements.isLoading" class="spinner-grow text-warning"
                                                role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>

                                            <div class="alert alert-danger  fade show" role="alert"
                                                v-else-if="departements.showMessage && !departements.isSuccess">
                                                <h4 class="alert-heading">Erreur </h4>
                                                <p></p>
                                                <hr>
                                                <p class="mb-0">{{ departements.message }}</p>
                                            </div>

                                            <select class="form-select" :readonly="createForm.isShow"
                                                :disabled="createForm.isShow" v-else id="floatingSelect"
                                                aria-label="Depar" v-model="createForm.fields.departement_id">
                                                <option v-for="(dept, i) in departements.result" :key="i"
                                                    :value="dept.id">{{ dept.name }}</option>

                                            </select>
                                            <label for="floatingSelect">Service</label>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-floating">

                                            <div v-if="profils.isLoading" class="spinner-grow text-warning"
                                                role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>

                                            <div class="alert alert-danger  fade show" role="alert"
                                                v-else-if="profils.showMessage && !profils.isSuccess">
                                                <h4 class="alert-heading">Erreur </h4>
                                                <p></p>
                                                <hr>
                                                <p class="mb-0">{{ profils.message }}</p>
                                            </div>

                                            <select class="form-select" :readonly="createForm.isShow"
                                                :disabled="createForm.isShow" v-else id="floatingSelect"
                                                aria-label="Depar" v-model="createForm.fields.profil_id">
                                                <option v-for="(dept, i) in profils.result" :key="i" :value="dept.id">{{
                                                    dept.name }}</option>

                                            </select>
                                            <label for="floatingSelect">Poste</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-floating">
                                            <input type="email" class="form-control" id="floatingEmail"
                                                placeholder="Your Email" :readonly="createForm.isShow"
                                                :disabled="createForm.isShow" v-model="createForm.fields.email"
                                                required>
                                            <label for="floatingEmail">Email</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-floating">
                                            <select class="form-select" :readonly="createForm.isShow"
                                                :disabled="createForm.isShow" id="floatingEmail" aria-label="State"
                                                v-model="createForm.fields.genre">
                                                <option>HOMME</option>
                                                <option>FEMME</option>

                                            </select>
                                            <label for="floatingEmail">Genre</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-floating">
                                            <input type="datetime-local" class="form-control" id="floatingEmail"
                                                placeholder="Your Email" :readonly="createForm.isShow"
                                                :disabled="createForm.isShow" v-model="createForm.fields.date_embauche">
                                            <label for="floatingEmail">Date d'embauche</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-floating">
                                            <input type="date" class="form-control" id="floatingEmail"
                                                placeholder="Your Email" :readonly="createForm.isShow"
                                                :disabled="createForm.isShow" v-model="createForm.fields.birth">
                                            <label for="floatingEmail">Date de naissance</label>
                                        </div>
                                    </div>


                                    <div class="col-md-6" v-if="!createForm.isShow">
                                        <div class="form-floating">
                                            <input type="password" class="form-control" id="floatingEmail"
                                                placeholder="Your Email" v-model="createForm.fields.password" required>
                                            <label for="floatingEmail">Mot de passe</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6" v-if="!createForm.isShow">
                                        <div class="form-floating">
                                            <input type="password" class="form-control" id="floatingEmail"
                                                placeholder="Your Email"
                                                v-model="createForm.fields.password_confirmation" required>
                                            <label for="floatingEmail">Confirmer mot de passe</label>
                                        </div>
                                    </div>





                                    <div class="text-end" v-if="!createForm.isShow">
                                        <button type="submit" class="btn btn-primary"><i
                                                class="bi bi-save  me-1"></i>Créer le compte</button>

                                    </div>
                                    <div class="text-end" v-else>
                                        <button type="button" @click="seletedItem(null, 'CREATE')"
                                            class="btn btn-secondary"><i class="bi bi-x-lg  me-1"></i>Fermer</button>

                                    </div>
                                </form>

                            </div>
                            <div class="card-body" v-else>
                                <div class="card-title d-flex justify-content-between">Modification d'un admin
                                    <i class="bi bi-x-lg" @click="() => { this.updateForm.isUpdate = false }"></i>
                                </div>

                                <div v-if="updateForm.isLoading"
                                    style="min-height: 50vh;flex-direction: column;align-items: center;justify-content: center;display: flex">
                                    <div class="spinner-grow text-warning" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                                <div class="alert alert-danger  fade show" role="alert"
                                    v-else-if="updateForm.showMessage && !updateForm.isSuccess">
                                    <h4 class="alert-heading">Erreur </h4>
                                    <p></p>
                                    <hr>
                                    <p class="mb-0">{{ updateForm.message }}</p>
                                </div>
                                <form class="row g-3" @submit.prevent="updateSubmit" v-if="!updateForm.isLoading">
                                    <div class="col-md-12">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" id="floatingEmail"
                                                placeholder="Your Email" required v-model="updateForm.fields.matricule">
                                            <label for="floatingEmail">Matricule</label>
                                        </div>
                                    </div>
                                    <div class="col-md-12 ">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" id="floatingName"
                                                placeholder="Your Name" v-model="updateForm.fields.firstname" required>
                                            <label for="floatingName">Nom</label>
                                        </div>
                                    </div>
                                    <div class="col-md-12 ">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" id="floatingName"
                                                placeholder="Your Name" v-model="updateForm.fields.lastname" required>
                                            <label for="floatingName">Prenoms</label>
                                        </div>
                                    </div>




                                    <div class="col-md-12">
                                        <div class="form-floating">

                                            <div v-if="chantiers.isLoading" class="spinner-grow text-warning"
                                                role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>

                                            <div class="alert alert-danger  fade show" role="alert"
                                                v-else-if="chantiers.showMessage && !chantiers.isSuccess">
                                                <h4 class="alert-heading">Erreur </h4>
                                                <p></p>
                                                <hr>
                                                <p class="mb-0">{{ chantiers.message }}</p>
                                            </div>

                                            <select class="form-select" v-else id="floatingSelect" aria-label="Depar"
                                                v-model="updateForm.fields.chantier_id">
                                                <option v-for="(dept, i) in chantiers.result" :key="i" :value="dept.id">
                                                    {{ dept.name }}</option>

                                            </select>
                                            <label for="floatingSelect">Centre de coût</label>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-floating">

                                            <div v-if="departements.isLoading" class="spinner-grow text-warning"
                                                role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>

                                            <div class="alert alert-danger  fade show" role="alert"
                                                v-else-if="departements.showMessage && !departements.isSuccess">
                                                <h4 class="alert-heading">Erreur </h4>
                                                <p></p>
                                                <hr>
                                                <p class="mb-0">{{ departements.message }}</p>
                                            </div>

                                            <select class="form-select" v-else id="floatingSelect" aria-label="Depar"
                                                v-model="updateForm.fields.departement_id">
                                                <option v-for="(dept, i) in departements.result" :key="i"
                                                    :value="dept.id">{{ dept.name }}</option>

                                            </select>
                                            <label for="floatingSelect">Services</label>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-floating">

                                            <div v-if="profils.isLoading" class="spinner-grow text-warning"
                                                role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>

                                            <div class="alert alert-danger  fade show" role="alert"
                                                v-else-if="profils.showMessage && !profils.isSuccess">
                                                <h4 class="alert-heading">Erreur </h4>
                                                <p></p>
                                                <hr>
                                                <p class="mb-0">{{ profils.message }}</p>
                                            </div>

                                            <select class="form-select" v-else id="floatingSelect" aria-label="Depar"
                                                v-model="updateForm.fields.profil_id">
                                                <option v-for="(dept, i) in profils.result" :key="i" :value="dept.id">{{
                                                    dept.name }}</option>

                                            </select>
                                            <label for="floatingSelect">Poste</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-floating">
                                            <input type="email" class="form-control" id="floatingEmail"
                                                placeholder="Your Email" v-model="updateForm.fields.email" required>
                                            <label for="floatingEmail">Email</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-floating">
                                            <select class="form-select" id="floatingEmail" aria-label="State"
                                                v-model="updateForm.fields.genre">
                                                <option>HOMME</option>
                                                <option>FEMME</option>

                                            </select>
                                            <label for="floatingEmail">Genre</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-floating">
                                            <input type="datetime-local" class="form-control" id="floatingEmail"
                                                placeholder="Your Email" v-model="updateForm.fields.date_embauche">
                                            <label for="floatingEmail">Date d'embauche</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-floating">
                                            <input type="date" class="form-control" id="floatingEmail"
                                                placeholder="Your Email" v-model="updateForm.fields.birth">
                                            <label for="floatingEmail">Date de naissance</label>
                                        </div>
                                    </div>


                                  

                                



                                    <div class="text-end">
                                        <button type="submit" class="btn btn-primary"><i
                                                class="bi bi-pencil  me-1"></i>Modifier le compte</button>

                                    </div>
                                </form>

                            </div>
                        </div>

                    </div>
                    <div class="col-xxl-6  col-md-12">

                        <div class="card">
                            <div class="card-body">
                                <div class="card-title">Liste des administrateurs</div>


                                <div class="alert alert-danger  fade show" role="alert"
                                    v-if="getData.showMessage && !getData.isSuccess">
                                    <h4 class="alert-heading">Erreur </h4>
                                    <p></p>
                                    <hr>
                                    <p class="mb-0">{{ createForm.message }}</p>
                                </div>
                                <vue3-datatable :loading="getData.isLoading" :rows="getData.result" :columns="cols"
                                    noDataContent="Vide" paginationInfo="De {0} à {1} sur {2} "
                                    :columnFilterLang="colsLang" :columnFilter="true">
                                    <template #actions="data">
                                        <div style="display: flex;justify-content: start;">


                                            <div @click="seletedItem(data.value, 'SHOW')" type=" button"
                                                class="btn btn-secondary mx-1"><i class="bi bi-eye"></i></div>
                                            <div @click="seletedItem(data.value, 'UPDATE')" type=" button"
                                                class="btn btn-primary mx-1"><i class="bi bi-pencil"></i></div>
                                            <div @click="seletedItem(data.value, 'DELETE')" data-bs-dismiss="modal"
                                                data-bs-toggle="modal" data-bs-target="#verticalycentered"
                                                type=" button" class="btn btn-danger mx-1"><i class="bi bi-trash"></i>
                                            </div>


                                        </div>

                                    </template>
                                </vue3-datatable>
                            </div>
                        </div>

                    </div>
                    <div class="modal fade" id="verticalycentered" tabindex="-1">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Suppression</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                </div>
                                <div class="modal-body text-danger" v-if="deleteForm.fields">
                                    Voulez-vous vraiment supprimer le admin <br> {{ deleteForm.fields.matricule }}
                                    <br><b>{{ deleteForm.fields.name }} </b>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary"
                                        data-bs-dismiss="modal">Fermer</button>
                                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                                        v-if="deleteForm.fields" @click="deleteSubmit"> <i
                                            class="bi bi-trash"></i>Supprimer</button>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </section>


        </main>
    </AppLayout>
</template>
<script>
import { notify } from '@/utils/utils';
import AppLayout from '@/components/Layouts/AppLayout.vue';
import BreadCrumb from '@/components/Layouts/BreadCrumb.vue';
import fetch from '@/utils/fetch';
import Vue3Datatable from "@bhplugin/vue3-datatable"
import { dateFromNow } from '../../utils/utils';


export default {
    name: "ListeAdmin",
    components: {
        AppLayout,
        BreadCrumb,
        Vue3Datatable
    },
    data() {
        return {
            cols: [{
                field: "id",
                title: "#",
                type: 'number',
                filter: false
            }, {
                field: "matricule",
                title: "Matricule",
                type: '',
                filter: true
            },
            {
                field: "firstname",
                cellRenderer: (user) => {
                    return `<b>${user.firstname} ${user.lastname} <b>`
                },
                title: "Nom ",
                type: "",
                filter: true
            },
            {
                field: "email",

                title: "Email",
                type: "",
                filter: false
            },
            {
                field: "date_embauche",
                title: "Embaucher le",
                cellRenderer: (point) => {
                    return dateFromNow(point.date_embauche)
                },

                type: "date",
            }, {
                field: "actions",
                title: "Actions",
                type: "",
                filter: false,
                sort: false,
            },
            ],
            colsLang: { no_filter: 'Aucun', contain: 'Contiens', not_contain: 'Ne contiens pas', equal: 'Egale', not_equal: 'Différent', start_with: 'Commence par', end_with: 'Termine par', greater_than: 'Supérieur à', greater_than_equal: 'Sup. ou égale à', less_than: 'Inférieur à', less_than_equal: 'Inf. ou égale à', is_null: 'Est null', is_not_null: 'Non null' },
            updateForm: {
                isUpdate: false,
                isLoading: false,
                isSuccess: false,
                showMessage: false,
                message: "",
                fields: {
                    firstname: null,
                    lastname: null,
                    matricule: null,
                    departement_id: null,
                    profil_id: null,
                    email: null,
                    genre: "HOMME",
                    role: "ADMIN",
                    date_embauche: null,
                    birth: null,
                    password: null,
                    password_confirmation: null,
                    is_enroll: false,
                },
                result: null
            },
            deleteForm: {
                isDelete: false,
                isLoading: false,
                isSuccess: false,
                showMessage: false,
                message: "",
                fields: null,
                result: null
            },
            createForm: {
                isShow: false,
                isLoading: false,
                isSuccess: false,
                showMessage: false,
                message: "",
                fields: {
                    firstname: null,
                    lastname: null,
                    matricule: null,
                    departement_id: null,
                    profil_id: null,
                    email: null,
                    genre: "HOMME",
                    role: "ADMIN",
                    date_embauche: null,
                    birth: null,
                    password: null,
                    password_confirmation: null,
                    is_enroll: false,
                },

                result: null
            },
            getData: {
                isLoading: false,
                isSuccess: true,
                showMessage: false,
                message: "",
                result: null,
            },

            chantiers: {
                isLoading: false,
                isSuccess: true,
                showMessage: false,
                message: "",
                result: null,
            },
            profils: {
                isLoading: false,
                isSuccess: true,
                showMessage: false,
                message: "",
                result: null,
            },
            departements: {
                isLoading: false,
                isSuccess: true,
                showMessage: false,
                message: "",
                result: null,
            },

        }
    },
    methods: {
        seletedItem(item, type) {
            if (type == "UPDATE") {
                this.updateForm.isUpdate = true
                this.updateForm.fields = { id: item.id, firstname: item.firstname, lastname: item.lastname, matricule: item.matricule, email: item.email, date_embauche: item.date_embauche, birth: item.birth, departement_id: item.departement_id, profil_id: item.profil_id,genre:item.genre,role:"ADMIN" }
            } else if (type == "DELETE") {
                this.deleteForm.isDelete = true
                this.deleteForm.fields = item
            } else if (type == "SHOW") {
                this.createForm.fields = { ...item, id: null }
                this.createForm.isShow = true

            } else if (type == "CREATE") {
                this.createForm.fields = {
                    firstname: null,
                    lastname: null,
                    matricule: null,
                    departement_id: null,
                    profil_id: null,
                    email: null,
                    genre: "HOMME",
                    role: "ADMIN",
                    date_embauche: null,
                    birth: null,
                    password: null,
                    password_confirmation: null,
                    is_enroll: false,
                }
                this.createForm.isShow = false

            }
        },

        getAdmins() {
            this.getData.isLoading = true;
            this.getData.showMessage = false;

            fetch({ url: `users/admins`, method: "GET" }).then((data) => {
                this.getData.result = data.content ?? data.data ?? data

                this.getData.isSuccess = true
            }).catch((err) => {
                this.getData.isSuccess = false;
                this.getData.showMessage = true;
                this.getData.message = `Erreur - ${err?.response?.data?.message ?? err}`
                this.getData.result = null
            }).finally(() => {
                this.getData.isLoading = false
            })
        },
        getChantiers() {
            this.chantiers.isLoading = true;
            this.chantiers.showMessage = false;

            fetch({ url: `chantier`, method: "GET" }).then((data) => {
                this.chantiers.result = data.content ?? data.data ?? data

                this.chantiers.isSuccess = true
            }).catch((err) => {
                this.chantiers.isSuccess = false;
                this.chantiers.showMessage = true;
                this.chantiers.message = `Erreur - ${err?.response?.data?.message ?? err}`
                this.chantiers.result = null
            }).finally(() => {
                this.chantiers.isLoading = false
            })
        },
        getProfils() {
            this.profils.isLoading = true;
            this.profils.showMessage = false;

            fetch({ url: `profil`, method: "GET" }).then((data) => {
                this.profils.result = data.content ?? data.data ?? data

                this.profils.isSuccess = true
            }).catch((err) => {
                this.profils.isSuccess = false;
                this.profils.showMessage = true;
                this.profils.message = `${err?.response?.data?.message ?? err}`
                this.profils.result = null
            }).finally(() => {
                this.profils.isLoading = false
            })
        },
        getDepartements() {
            this.departements.isLoading = true;
            this.departements.showMessage = false;

            fetch({ url: `departement`, method: "GET" }).then((data) => {
                this.departements.result = data.content ?? data.data ?? data

                this.departements.isSuccess = true
            }).catch((err) => {
                this.departements.isSuccess = false;
                this.departements.showMessage = true;
                this.departements.message = `Erreur - ${err?.response?.data?.message ?? err}`
                this.departements.result = null
            }).finally(() => {
                this.departements.isLoading = false
            })
        },



        handleSubmit() {
            this.createForm.isLoading = true;
            this.createForm.showMessage = false;
            let data = this.createForm.fields;

            delete this.createForm.fields.password_confirmation
            delete this.createForm.fields.chantier_id


            fetch({ url: `users`, method: "POST", data }).then(() => {


                this.createForm.isSuccess = true
                this.getAdmins()

                notify({ message: "L'admin est enregistré", type: "success" })
            }).catch((err) => {
                this.createForm.isSuccess = false;
                this.createForm.showMessage = true;
                let message = `${err?.response?.data?.message ?? err}`
                this.createForm.message = message;
                notify({ message: "Erreur,l'admin n'a pas ete enregistré", type: "error" })

            }).finally(() => {
                this.createForm.isLoading = false
                this.createForm.fields = {
                    firstname: null,
                    lastname: null,
                    matricule: null,
                    departement_id: null,
                    profil_id: null,
                    email: null,
                    genre: "HOMME",
                    role: "ADMIN",
                    date_embauche: null,
                    birth: null,
                    password: null,
                    password_confirmation: null,
                    is_enroll: false,
                }

            })
        },
        updateSubmit() {
            this.updateForm.isLoading = true;
            this.updateForm.showMessage = false;
            let data = this.updateForm.fields

            fetch({ url: `users/${data.id ?? "-"}`, method: "PUT", data }).then(() => {

                this.updateForm.isSuccess = true
                notify({ message: "L'admin a été modifié", type: "success" })
                this.getAdmins()
            }).catch((err) => {
                this.updateForm.isSuccess = false;
                this.updateForm.showMessage = true;
                let message = `${err?.response?.data?.message ?? err}`
                this.updateForm.message = message;
                notify({ message: "Erreur, l'admin n'a pas pu etre modifié", type: "error" })

            }).finally(() => {
                this.updateForm.isLoading = false
                this.updateForm.isUpdate = false;
                this.updateForm.fields = {
                    description: null,
                    code: null,
                    name: null
                }

            })
        },
        deleteSubmit() {
            this.deleteForm.isLoading = true;
            this.deleteForm.showMessage = false;
            let data = this.deleteForm.fields

            fetch({ url: `users/${data.id ?? "-"}`, method: "DELETE" }).then(() => {

                this.deleteForm.isSuccess = true
                notify({ message: "L'admin a été supprimer", type: "success" })
                this.getAdmins()
            }).catch((err) => {
                this.deleteForm.isSuccess = false;
                this.deleteForm.showMessage = true;
                let message = `${err?.response?.data?.message ?? err}`
                this.deleteForm.message = message;
                notify({ message: "Erreur,L'admin n'a pas pu etre supprimé", type: "error" })

            }).finally(() => {
                this.deleteForm.isLoading = false
                this.deleteForm.isDelete = false;
                this.deleteForm.fields = {
                    description: null,
                    code: null,
                    name: null
                }

            })
        },

    },
    mounted() {

        this.getChantiers()
        this.getProfils()
        this.getDepartements()
        this.getAdmins()

    }
}
</script>