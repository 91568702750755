import { createApp } from 'vue'
import App from './App.vue'

import router from "@/routes/index"
import '@bhplugin/vue3-datatable/dist/style.css'

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'


const app = createApp(App)
app.component('VueDatePicker', VueDatePicker);
app.use(router)
.mount('#app')
