<template>
  <AppLayout>
    <main id="main" class="main">
      <!-- End Page Title -->

      <section class="section">
        <div class="row">
          <div class="col-lg-12">

            <div class="card">

              <div class="card-body">
                <h5 class="card-title">
                  {{ isLoading ?
                    "Modification en cour" :
                    "Modification"
                  }}
                  <br>

                  <b style="font-size: 13px;font-weight: 400;">
                    {{ isLoading ?
                      "La modification en cour,Patientez" :
                      "Veuillez bien renseigner les champs"
                    }}
                  </b>
                </h5>

                <div v-if="isLoading">
                  <div class="spinner-grow text-warning" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>


                <!-- Advanced Form Elements -->
                <form @submit.prevent="updatePointing" v-else>
                  <div
                    :class="isSuccess ? 'alert alert-success  fade show text-md' : 'alert alert-danger  fade show text-md'"
                    role="alert" v-if="showMessage">

                    <h4 v-if="isSuccess" class="alert-heading">Bravo </h4>
                    <h4 v-else class="alert-heading">Oups Erreur </h4>

                    <p class="mb-0">{{ message }}</p>
                  </div>
                  <div class="row mb-3" >
                    <div class="col-sm-12">

                      <div class="form-floating mb-3">
                        <input type="time" v-model="result.hours" required class="form-control" id="floatingInput">
                        <label for="floatingInput">Heure:</label>
                      </div>
                      <div class="form-floating mb-3">
                        <input type="date" v-model="fields.date" required class="form-control" id="floatingInput">
                        <label for="floatingInput">Date:</label>
                      </div>
                      <div class="form-floating mb-3" v-if="chantiers.isSuccess">
                        <select class="form-select" required v-model="fields.pointing_at" id="floatingSelect"
                          aria-label="Floating label select example">
                          <option v-for="(cht, i) in chantiers.data" :key="i" :value="cht.id">{{ cht.name }}</option>

                        </select>
                        <label for="floatingSelect">Centres de coût</label>
                      </div>
                      <div class="form-floating mb-3">
                        <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"
                          required readonly disabled :value="fields.saveby.firstname">
                        <label for="floatingInput">Enrégistre par:</label>
                      </div>

                      <div class="form-floating mb-3">
                        <textarea class="form-control" v-model="fields.commentaire" required
                          placeholder="Leave a comment here" id="floatingTextarea" style="height: 150px;"></textarea>
                        <label for="floatingTextarea">Commentaire</label>
                      </div>

                    </div>
                  </div>

                  <div class="d-flex justify-content-end mt-3">
                    <button type="submit" class="btn btn-primary" v-if="chantiers.isSuccess">Modifier</button>
                    <button type="button" class="btn btn-secondary mx-2"
                      @click="() => { isOpen = false }">Fermer</button>
                  </div>

                </form>
                <!-- End General Form Elements -->

              </div>
            </div>

          </div>


        </div>
      </section>

    </main>
  </AppLayout>
</template>
<script>
import AppLayout from '../../components/Layouts/AppLayout.vue';
import { getChantierApi } from '../../utils/api';
import fetch from '../../utils/fetch';

export default {
  name: "EditPointage",
  components: {
    AppLayout
  },
  data() {
    return {
      requiredFields: ["hours", "date", "pointing_at", "commentaire"],
      fields: null,
      isOpen: false,
      showMessage: false,
      message: "",
      isLoading: false,
      isSuccess: false,
      result: null,
      refreshData: false,
      selectPointage:null
    }
  },
  methods: {
    updatePointing() {
      this.isLoading = true;
      this.showMessage = false;
      const id = this.$route.params.id;
      let data = {};
      this.requiredFields.forEach((field) => {
        data[field] = this.fields[field]
      })

      // if()
      fetch({ url: `pointing/${id ?? "-"}`, data, method: "PUT" }).then(() => {
        this.isSuccess = true;

        this.message = `Modification reussie`
      }).catch((err) => {
        this.isSuccess = false;

        this.message = `${err?.response?.data?.message ?? err}`
        this.result = null
      }).finally(() => {
        this.isLoading = false;
        this.showMessage = true;
      })
    },
    async getChantiers() {
      this.chantiers.isLoading = true;
      this.chantiers = await getChantierApi();
    },
    getPointageInfo() {
      this.isLoading = true;
      this.showMessage = false
      const id = this.$route.params.id;
      console.log('id', id)

      fetch({ url: `pointing/${id ?? "-"}`, method: "GET" }).then((data) => {
        this.result = data.content ?? data.data ?? data
       
        this.isSuccess = true
      }).catch((err) => {
        this.isSuccess = false;
        this.showMessage = true;
        this.message = `Erreur - ${err}`
        this.result = null
      }).finally(() => {
        this.isLoading = false;
        this.updateData.refresh = false;
        this.updateData.isOpen = false;
        this.updateData.fields = null;
        this.deleteData.isOpen = false;
      })
    },
  },
  mounted() {
    this.getPointageInfo();
    this.getChantiers()
  }

}
</script>