import moment from "moment"

moment.locale('fr')

import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export const dateFromNow = (date)=>{
    return moment(date).fromNow();
}
export const hourFormat = (hour)=>{
    return moment(hour).format("H:m:s");
}
export const  notify=(notif)=>{
    toast(notif.message, {
        "theme": "colored",
        "type": notif.type,
        "dangerouslyHTMLString": true
    })
}