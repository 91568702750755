<template>
    <component :is="this.$route.meta.layoutComponent">
        <slot></slot>
        <!-- <slot/> -->
    </component>
</template>
<script>
import fetch from '@/utils/fetch';

export default {
    name: "AppLayout",
    methods: {
        checkToken() {
            fetch({ url: "user", method: "GET" }).then((isOk) => {
                console.log('isOk', isOk)
            }).catch((err) => {
                console.log('err', err)
            })
        }
    },
    mounted(){
        this.checkToken()
    }
}
</script>