<template>
    <div class="pagetitle">
      <h1>{{ title }}</h1>
      <nav>
        <ol class="breadcrumb">
          <li class="breadcrumb-item" v-for="(prev, i) in previous" :key="i"><router-link :to="prev.goTo" >{{ prev.label }}</router-link></li>
          <li class="breadcrumb-item active">{{title}}</li>
        </ol>
      </nav>
    </div>
</template>

<script>
export default {
    name:"BreadCrumb",
    props:{
        title:String,
        previous:{
            type:Array,
            default:()=>{
                return [
                   {
                        label:"Home",
                        goTo:{name:"Dashboard"} 
                   },
                   {
                        label:"Previs",
                        goTo:{name:"Dashboard"}  
                   },

                ]
            }
        }
    }


}
</script>