import fetch from "./fetch"

export const getChantierApi= async (id=null)=>{
    try {
        const url = id ? `chantier/${id}` :"chantier"
        const data  =  await fetch({method:"GET",url});

        return {isLoading:false,showMessage:false,message:"Success",isSuccess:true,data:data?.content ??  data }        
    } catch (error) {
        return  {isLoading:false,showMessage:true,message:"Erreur",isSuccess:false,data:null }  
    }
}
export const getWorkerApSi= async (id=null)=>{
    try {
        const url = id ? `users/${id}` :"users"
        const data  =  await fetch({method:"GET",url});

        return {isLoading:false,showMessage:false,message:"Success",isSuccess:true,data:data?.content ??  data }        
    } catch (error) {
        return  {isLoading:false,showMessage:true,message:"Erreur",isSuccess:false,data:null }  
    }
}
export const getWorkerApi= async (id=null)=>{
    try {
        const url = id ? `"/users/trashed/${id}` :"/users/trashed"
        const data  =  await fetch({method:"GET",url});

        return {isLoading:false,showMessage:false,message:"Success",isSuccess:true,data:data?.content ??  data }        
    } catch (error) {
        return  {isLoading:false,showMessage:true,message:"Erreur",isSuccess:false,data:null }  
    }
}
export const getDepartementApi= async (id=null)=>{
    try {
        const url = id ? `departement/${id}` :"departement"
        const data  =  await fetch({method:"GET",url});

        return {isLoading:false,showMessage:false,message:"Success",isSuccess:true,data:data?.content ??  data }        
    } catch (error) {
        return  {isLoading:false,showMessage:true,message:"Erreur",isSuccess:false,data:null }  
    }
}
