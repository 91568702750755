<template>
    <AppLayout>
        <main id="main" class="main">

            <section class="section">
                <BreadCrumb
                    :previous="[
                        { label: 'Accueil', goTo: { name: 'Dashboard' } }, { label: 'Configurations', goTo: { name: 'Dashboard' } }]"
                    title="Service" />
                <!-- End Page Title -->
                <div class="row">




                    <div class="col-xxl-6  col-md-12">

                        <div class="card">
                            <div class="card-body" v-if="!updateForm.isUpdate">
                                <div class="card-title">Ajouter un Service</div>

                                <div v-if="createForm.isLoading"
                                    style="min-height: 50vh;flex-direction: column;align-items: center;justify-content: center;display: flex">
                                    <div class="spinner-grow text-warning" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                                <div class="alert alert-danger  fade show" role="alert"
                                    v-else-if="createForm.showMessage && !createForm.isSuccess">
                                    <h4 class="alert-heading">Erreur </h4>
                                    <p></p>
                                    <hr>
                                    <p class="mb-0">{{ createForm.message }}</p>
                                </div>
                                <form class="row g-3" @submit.prevent="handleSubmit" v-if="!createForm.isLoading && !chantiers.isLoading">

                                    <div class="col-md-12 ">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" id="floatingName"
                                                placeholder="Your Name" v-model="createForm.fields.code" :readonly="createForm.isShow" :disabled="createForm.isShow" required>
                                            <label for="floatingName">Code du service</label>
                                        </div>
                                    </div>
                                    <div class="col-md-12 ">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" id="floatingName"
                                                placeholder="Your Name" v-model="createForm.fields.name" :readonly="createForm.isShow" :disabled="createForm.isShow" required>
                                            <label for="floatingName">Nom du service</label>
                                        </div>
                                    </div> <div class="col-md-12">
                                            <div class="form-floating">

                                                <div v-if="chantiers.isLoading" class="spinner-grow text-warning"
                                                    role="status">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div>

                                                <div class="alert alert-danger  fade show" role="alert"
                                                    v-else-if="chantiers.showMessage && !chantiers.isSuccess">
                                                    <h4 class="alert-heading">Erreur </h4>
                                                    <p></p>
                                                    <hr>
                                                    <p class="mb-0">{{ chantiers.message }}</p>
                                                </div>

                                                <select class="form-select" v-else id="floatingSelect"
                                                    aria-label="Depar" v-model="createForm.fields.chantier_id" required>
                                                    <option v-for="(cht, i) in chantiers.result" :key="i"
                                                        :value="cht.id">{{ cht.name }}</option>

                                                </select>
                                                <label for="floatingSelect">Centre de coût</label>
                                            </div>
                                        </div>
                                    <div class="col-md-12 ">
                                        <div class="form-floating">
                                            <textarea class="form-control" id="floatingName" placeholder="Your Name" :cols="4"
                                                v-model="createForm.fields.description" :readonly="createForm.isShow" :disabled="createForm.isShow" ></textarea>
                                            <label for="floatingName">Description</label>
                                        </div>
                                    </div>
                                    <div class="text-end" v-if="!createForm.isShow">
                                        <button type="submit" class="btn btn-primary"><i
                                                class="bi bi-save  me-1"></i>Ajouter</button>

                                    </div>
                                    <div class="text-end" v-else>
                                        <button type="button" @click="seletedItem(null,'CREATE')" class="btn btn-secondary"><i
                                                class="bi bi-x-lg  me-1"></i>Fermer</button>

                                    </div>
                                </form>
                            </div>
                            <div class="card-body" v-else>
                                <div class="card-title d-flex justify-content-between">Modification d'un service
                                    <i class="bi bi-x-lg" @click="() => { this.updateForm.isUpdate = false }"></i>
                                </div>

                                <div v-if="updateForm.isLoading"
                                    style="min-height: 50vh;flex-direction: column;align-items: center;justify-content: center;display: flex">
                                    <div class="spinner-grow text-warning" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                                <div class="alert alert-danger  fade show" role="alert"
                                    v-else-if="updateForm.showMessage && !updateForm.isSuccess">
                                    <h4 class="alert-heading">Erreur </h4>
                                    <p></p>
                                    <hr>
                                    <p class="mb-0">{{ updateForm.message }}</p>
                                </div>
                                <form class="row g-3" @submit.prevent="updateSubmit" v-if="!updateForm.isLoading && !chantiers.isLoading">

                                    <div class="col-md-12 ">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" id="floatingName"
                                                placeholder="Your Name" v-model="updateForm.fields.code" required>
                                            <label for="floatingName">Code du service</label>
                                        </div>
                                    </div>
                                    <div class="col-md-12 ">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" id="floatingName"
                                                placeholder="Your Name" v-model="updateForm.fields.name" required>
                                            <label for="floatingName">Nom du service</label>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                            <div class="form-floating">

                                                <div v-if="chantiers.isLoading" class="spinner-grow text-warning"
                                                    role="status">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div>

                                                <div class="alert alert-danger  fade show" role="alert"
                                                    v-else-if="chantiers.showMessage && !chantiers.isSuccess">
                                                    <h4 class="alert-heading">Erreur </h4>
                                                    <p></p>
                                                    <hr>
                                                    <p class="mb-0">{{ chantiers.message }}</p>
                                                </div>

                                                <select class="form-select" v-else id="floatingSelect"
                                                    aria-label="Depar" v-model="updateForm.fields.chantier_id" required>
                                                    <option v-for="(cht, i) in chantiers.result" :key="i"
                                                        :value="cht.id">{{ cht.name }}</option>

                                                </select>
                                                <label for="floatingSelect">Centre de coût</label>
                                            </div>
                                        </div>
                                    <div class="col-md-12 ">
                                        <div class="form-floating">
                                            <textarea class="form-control" id="floatingName" placeholder="Your Name"
                                                v-model="updateForm.fields.description"></textarea>
                                            <label for="floatingName">Description</label>
                                        </div>
                                    </div>
                                    <div class="text-end">
                                        <button type="submit" class="btn btn-primary"><i class="bi bi-pencil  me-1"
                                                @click="updateSubmit"></i>Modifier</button>

                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                    <div class="col-xxl-6  col-md-12">

                        <div class="card">
                            <div class="card-body">
                                <div class="card-title">Liste des services</div>

                              
                                <div class="alert alert-danger  fade show" role="alert"
                                    v-if="getData.showMessage && !getData.isSuccess">
                                    <h4 class="alert-heading">Erreur </h4>
                                    <p></p>
                                    <hr>
                                    <p class="mb-0">{{ createForm.message }}</p>
                                </div>
                                <vue3-datatable :loading="getData.isLoading" :rows="getData.result" :columns="cols" noDataContent="Vide"
                                    paginationInfo="De {0} à {1} sur {2} " :columnFilterLang="colsLang"
                                    :columnFilter="true">
                                    <template #actions="data">
                                        <div style="display: flex;justify-content: start;">


                                            <div @click="seletedItem(data.value, 'SHOW')" type=" button"
                                                class="btn btn-secondary mx-1"><i class="bi bi-eye"></i></div>
                                            <div @click="seletedItem(data.value, 'UPDATE')" type=" button"
                                                class="btn btn-primary mx-1"><i class="bi bi-pencil"></i></div>
                                            <div @click="seletedItem(data.value, 'DELETE')" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#verticalycentered"
                                                type=" button" class="btn btn-danger mx-1"><i class="bi bi-trash"></i>
                                            </div>


                                        </div>

                                    </template>
                                </vue3-datatable>
                            </div>
                        </div>

                    </div>
                    <div class="modal fade" id="verticalycentered" tabindex="-1">
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Suppression</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body text-danger" v-if="deleteForm.fields">
                    Voulez-vous vraiment supprimer le service <br> {{deleteForm.fields.code}} <br><b>{{deleteForm.fields.name}}  </b> 
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                      <button type="button" class="btn btn-danger" data-bs-dismiss="modal"   v-if="deleteForm.fields" @click="deleteSubmit"> <i class="bi bi-trash"></i>Supprimer</button>
                    </div>
                  </div>
                </div>
              </div>


                </div>
            </section>


        </main>
    </AppLayout>
</template>
<script>
import { notify } from '@/utils/utils';
import AppLayout from '@/components/Layouts/AppLayout.vue';
import BreadCrumb from '@/components/Layouts/BreadCrumb.vue';
import fetch from '@/utils/fetch';
import Vue3Datatable from "@bhplugin/vue3-datatable"


export default {
    name: "ListeDepartement",
    components: {
        AppLayout,
        BreadCrumb,
        Vue3Datatable
    },
    data() {
        return {
            cols: [{
                field: "code",
                title: "Code",
                type: 'number',
                filter: false
            }, {
                field: "name",
                title: "Nom du Service",
                filter: false
            }, {
                field: "chantier.name",
                title: "Chantier",
                filter: false
            },{
                field: "description",
                title: "Description",
                type: '',
                filter: false
            },
            {
                field: "actions",
                title: "Actions",
                type: "",
                filter: false,
                sort: false,
            },
            ],
            colsLang: { no_filter: 'Aucun', contain: 'Contiens', not_contain: 'Ne contiens pas', equal: 'Egale', not_equal: 'Différent', start_with: 'Commence par', end_with: 'Termine par', greater_than: 'Supérieur à', greater_than_equal: 'Sup. ou égale à', less_than: 'Inférieur à', less_than_equal: 'Inf. ou égale à', is_null: 'Est null', is_not_null: 'Non null' },
            updateForm: {
                isUpdate: false,
                isLoading: false,
                isSuccess: false,
                showMessage: false,
                message: "",
                fields: {
                    name: null,
                    description: null,
                    chantier_id:null,

                },
                result: null
            },
            deleteForm: {
                isDelete: false,
                isLoading: false,
                isSuccess: false,
                showMessage: false,
                message: "",
                fields:null,
                result: null
            },
            createForm: {
                isShow: false,
                isLoading: false,
                isSuccess: false,
                showMessage: false,
                message: "",
                fields: {
                    name: null,
                    description: null,
                    code: null,
                    chantier_id:null,
                },

                result: null
            },
            getData: {
                isLoading: false,
                isSuccess: true,
                showMessage: false,
                message: "",
                result: null,
            },
            chantiers: {
                isLoading: false,
                isSuccess: true,
                showMessage: false,
                message: "",
                result: null,
            }

        }
    },
    methods: {
        seletedItem(item, type) {
            if (type == "UPDATE") {
                this.updateForm.isUpdate = true
                this.updateForm.fields = { id: item.id, code: item.code, name: item.name, description: item.description,chantier_id:item.chantier_id }
            } else if (type == "DELETE") {
                this.deleteForm.isDelete = true
                this.deleteForm.fields = item
            } else if (type == "SHOW") {
                this.createForm.fields = { ...item, id: null }
                this.createForm.isShow = true

            }else if(type =="CREATE"){
                this.createForm.fields = {
                    name:null,
                    description:null,
                    code:null
                }
                this.createForm.isShow = false

            }
        },

        getDepartements() {
            this.getData.isLoading = true;
            this.getData.showMessage = false;

            fetch({ url: `departement`, method: "GET" }).then((data) => {
                this.getData.result = data.content ?? data.data ?? data

                this.getData.isSuccess = true
            }).catch((err) => {
                this.getData.isSuccess = false;
                this.getData.showMessage = true;
                this.getData.message = `Erreur - ${err?.response?.data?.message ?? err}`
                this.getData.result = null
            }).finally(() => {
                this.getData.isLoading = false
            })
        },
        getChantiers() {
            this.chantiers.isLoading = true;
            this.chantiers.showMessage = false;

            fetch({ url: `chantier`, method: "GET" }).then((data) => {
                this.chantiers.result = data.content ?? data.data ?? data

                this.chantiers.isSuccess = true
            }).catch((err) => {
                this.chantiers.isSuccess = false;
                this.chantiers.showMessage = true;
                this.chantiers.message = `Erreur - ${err?.response?.data?.message ?? err}`
                this.chantiers.result = null
            }).finally(() => {
                this.chantiers.isLoading = false
            })
        },



        handleSubmit() {
            this.createForm.isLoading = true;
            this.createForm.showMessage = false;
            let data = this.createForm.fields;


            fetch({ url: `departement`, method: "POST", data }).then(() => {


                this.createForm.isSuccess = true
                this.getDepartements()

                notify({ message: "Le service est enregistré", type: "success" })
            }).catch((err) => {
                this.createForm.isSuccess = false;
                this.createForm.showMessage = true;
                let message = `${err?.response?.data?.message ?? err}`
                this.createForm.message = message;
                notify({ message: "Erreur-Le service n'a pas ete enregistré", type: "error" })

            }).finally(() => {
                this.createForm.isLoading = false
                this.createForm.fields = {
                    name:null,
                    code:null,
                    description:null
                }

            })
        },
        updateSubmit() {
            this.updateForm.isLoading = true;
            this.updateForm.showMessage = false;
            let data = this.updateForm.fields

            fetch({ url: `departement/${data.id ?? "-"}`, method: "PUT", data }).then(() => {

                this.updateForm.isSuccess = true
                notify({ message: "Service  modifié", type: "success" })
                this.getDepartements()
            }).catch((err) => {
                this.updateForm.isSuccess = false;
                this.updateForm.showMessage = true;
                let message = `${err?.response?.data?.message ?? err}`
                this.updateForm.message = message;
                notify({ message: "Erreur, Le service n'a pas pu etre modifié", type: "error" })

            }).finally(() => {
                this.updateForm.isLoading = false
                this.updateForm.isUpdate = false;
                this.updateForm.fields = {
                    description: null,
                    code: null,
                    name: null
                }

            })
        },
        deleteSubmit() {
            this.deleteForm.isLoading = true;
            this.deleteForm.showMessage = false;
            let data = this.deleteForm.fields

            fetch({ url: `departement/${data.id ?? "-"}`, method: "DELETE" }).then(() => {

                this.deleteForm.isSuccess = true
                notify({ message: "Le service a été supprimer", type: "success" })
                this.getDepartements()
            }).catch((err) => {
                this.deleteForm.isSuccess = false;
                this.deleteForm.showMessage = true;
                let message = `${err?.response?.data?.message ?? err}`
                this.deleteForm.message = message;
                notify({ message: "Erreur-Le service n'a pas pu etre supprimé", type: "error" })

            }).finally(() => {
                this.deleteForm.isLoading = false
                this.deleteForm.isDelete = false;
                this.deleteForm.fields = {
                    description: null,
                    code: null,
                    name: null
                }

            })
        },

    },
    mounted() {
        this.getChantiers()
        this.getDepartements()

    }
}
</script>