<template>
    <AppLayout>
        <main id="main" class="main">

            <section class="section">
                <BreadCrumb
                    :previous="[
                        { label: 'Accueil', goTo: { name: 'Dashboard' } }, { label: 'Configurations', goTo: { name: 'Dashboard' } }]"
                    title="Chaniter" />
                <!-- End Page Title -->
                <div class="row">




                    <div class="col-xxl-6  col-md-12">

                        <div class="card">
                            <div class="card-body" v-if="!updateForm.isUpdate">
                                <div class="card-title">Ajouter un centre de coût</div>
                                
                                <div v-if="createForm.isLoading"
                                    style="min-height: 50vh;flex-direction: column;align-items: center;justify-content: center;display: flex">
                                    <div class="spinner-grow text-warning" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                                <div class="alert alert-danger  fade show" role="alert"
                                    v-else-if="createForm.showMessage && !createForm.isSuccess">
                                    <h4 class="alert-heading">Erreur </h4>
                                    <p></p>
                                    <hr>
                                    <p class="mb-0">{{ createForm.message }}</p>
                                </div>
                                <form class="row g-3" @submit.prevent="handleSubmit" v-if="!createForm.isLoading">
                                    
                                    <div class="col-md-12 ">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" id="floatingName"
                                                placeholder="Your Name" v-model="createForm.fields.code" required>
                                            <label for="floatingName">Code du centre de coût</label>
                                        </div>
                                    </div>
                                    <div class="col-md-12 ">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" id="floatingName"
                                                placeholder="Your Name" v-model="createForm.fields.name" required>
                                            <label for="floatingName">Nom du centre de coût</label>
                                        </div>
                                    </div>
                                    <div class="col-md-12 ">
                                        <div class="form-floating">
                                            <textarea class="form-control" id="floatingName"
                                                placeholder="Your Name" v-model="createForm.fields.description" ></textarea>
                                            <label for="floatingName">Description</label>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <button type="submit" class="btn btn-primary"><i
                                                class="bi bi-save  me-1"></i>Crée r</button>

                                    </div>
                                </form>
                            </div>
                            <div class="card-body" v-else>
                                <div class="card-title d-flex justify-content-between">Modification d'un centre de coût
                                    <i class="bi bi-close" @click="()=>{this.updateForm.isUpdate = false}"></i>
                                </div>
                                
                                <div v-if="updateForm.isLoading"
                                    style="min-height: 50vh;flex-direction: column;align-items: center;justify-content: center;display: flex">
                                    <div class="spinner-grow text-warning" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                                <div class="alert alert-danger  fade show" role="alert"
                                    v-else-if="updateForm.showMessage && !updateForm.isSuccess">
                                    <h4 class="alert-heading">Erreur </h4>
                                    <p></p>
                                    <hr>
                                    <p class="mb-0">{{ updateForm.message }}</p>
                                </div>
                                <form class="row g-3" @submit.prevent="updateSubmit" v-if="!updateForm.isLoading">
                                    
                                    <div class="col-md-12 ">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" id="floatingName"
                                                placeholder="Your Name" v-model="updateForm.fields.code" required>
                                            <label for="floatingName">Code du centre de coût</label>
                                        </div>
                                    </div>
                                    <div class="col-md-12 ">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" id="floatingName"
                                                placeholder="Your Name" v-model="updateForm.fields.name" required>
                                            <label for="floatingName">Nom du centre de coût</label>
                                        </div>
                                    </div>
                                    <div class="col-md-12 ">
                                        <div class="form-floating">
                                            <textarea class="form-control" id="floatingName"
                                                placeholder="Your Name" v-model="updateForm.fields.description" ></textarea>
                                            <label for="floatingName">Description</label>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <button type="submit" class="btn btn-primary"><i
                                                class="bi bi-save  me-1"></i>Créer</button>

                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                    <div class="col-xxl-6  col-md-12">

                        <div class="card">
                            <div class="card-body">
                                <div class="card-title">Liste des centre de coût</div>
                               
                                <div v-if="getData.isLoading"
                                    style="min-height: 50vh;flex-direction: column;align-items: center;justify-content: center;display: flex">
                                    <div class="spinner-grow text-warning" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                                <div class="alert alert-danger  fade show" role="alert"
                                    v-else-if="getData.showMessage && !getData.isSuccess">
                                    <h4 class="alert-heading">Erreur </h4>
                                    <p></p>
                                    <hr>
                                    <p class="mb-0">{{ createForm.message }}</p>
                                </div>
                                <vue3-datatable :rows="getData.result" :columns="cols" noDataContent="Vide"
                                        paginationInfo="De {0} à {1} sur {2} " :columnFilterLang="colsLang"
                                        :columnFilter="true">
                                        <template #actions="data">
                                            <div style="display: flex;justify-content: start;">


                                                <router-link
                                                    :to="{ name: 'PointageShow', params: { id: data.value.id } }"
                                                    type=" button" class="btn btn-secondary mx-1"><i
                                                        class="bi bi-eye"></i></router-link>

                                            </div>

                                        </template>
                                    </vue3-datatable>
                            </div>
                        </div>

                    </div>


                </div>
            </section>


        </main>
    </AppLayout>
</template>
<script>
import { notify } from '@/utils/utils';
import AppLayout from '@/components/Layouts/AppLayout.vue';
import BreadCrumb from '@/components/Layouts/BreadCrumb.vue';
import fetch from '@/utils/fetch';
import Vue3Datatable from "@bhplugin/vue3-datatable"


export default {
    name: "CreateChantier",
    components: {
        AppLayout,
        BreadCrumb,
        Vue3Datatable
    },
    data() {
        return {
            cols: [{
                field: "code",
                title: "Code",
                type: 'number',
                filter: false
            },{
                field: "name",
                title: "Centre de coût",
                filter: false
            },{
                field: "description",
                title: "Description",
                type: '',
                filter: false
            },
             {
                field: "actions",
                title: "Actions",
                type: "",
                filter: false,
                sort: false,
            },
            ],
            colsLang: { no_filter: 'Aucun', contain: 'Contiens', not_contain: 'Ne contiens pas', equal: 'Egale', not_equal: 'Différent', start_with: 'Commence par', end_with: 'Termine par', greater_than: 'Supérieur à', greater_than_equal: 'Sup. ou égale à', less_than: 'Inférieur à', less_than_equal: 'Inf. ou égale à', is_null: 'Est null', is_not_null: 'Non null' },
            updateForm: {
                isUpdate: false,
                isLoading: false,
                isSuccess: false,
                showMessage: false,
                message: "",
                fields: {
                    name: null,
                    description: null,
                },
                result: null
            },
            deleteForm: {
                isUpdate: false,
                isLoading: false,
                isSuccess: false,
                showMessage: false,
                message: "",
                fields: {
                    id: null,
                },
                result: null
            },
            createForm: {
                isUpdate: false,
                isLoading: false,
                isSuccess: false,
                showMessage: false,
                message: "",
                fields: {
                    name: null,
                    description:null,
                    code:null
                },
                result: null
            },
            getData: {
                isLoading: false,
                isSuccess: true,
                showMessage: false,
                message: "",
                result: null,
            },
            departements: {
                isLoading: false,
                isSuccess: true,
                showMessage: false,
                message: "",
                result: null,
            },
            profils: {
                isLoading: false,
                isSuccess: true,
                showMessage: false,
                message: "",
                result: null,
            },
            chantiers: {
                isLoading: false,
                isSuccess: true,
                showMessage: false,
                message: "",
                result: null,
            }

        }
    },
    methods: {

      
        getChantiers() {
            this.getdata.isLoading = true;
            this.getdata.showMessage = false;

            fetch({ url: `chantier`, method: "GET" }).then((data) => {
                this.getdata.result = data.content ?? data.data ?? data

                this.getdata.isSuccess = true
            }).catch((err) => {
                this.getdata.isSuccess = false;
                this.getdata.showMessage = true;
                this.getdata.message = `Erreur - ${err?.response?.data?.message ?? err}`
                this.getdata.result = null
            }).finally(() => {
                this.getdata.isLoading = false
            })
        },
     


        handleSubmit() {
            this.createForm.isLoading = true;
            this.createForm.showMessage = false;
            let data = {};
            this.createForm.requiredFields.forEach((field) => {
                data[field] = this.createForm.fields[field]
            })


            fetch({ url: `users`, method: "POST", data }).then(() => {
               

                this.createForm.isSuccess = true
                this.getChantiers()

                notify({ message: "Compte crée", type: "success" })
            }).catch((err) => {
                this.createForm.isSuccess = false;
                this.createForm.showMessage = true;
                let message = `${err?.response?.data?.message ?? err}`
                this.createForm.message = message;
                notify({ message: "Erreur-Compte non enregistré", type: "error" })

            }).finally(() => {
                this.createForm.isLoading = false

            })
        },
        updateSubmit() {
            this.updateForm.isLoading = true;
            this.updateForm.showMessage = false;
            let data = this.updateForm.fields

            fetch({ url: `chantier`, method: "PUT", data }).then(() => {

                this.updateForm.isSuccess = true
                notify({ message: "Chantier a été modifié", type: "success" })
                this.getChantiers()
            }).catch((err) => {
                this.updateForm.isSuccess = false;
                this.updateForm.showMessage = true;
                let message = `${err?.response?.data?.message ?? err}`
                this.updateForm.message = message;
                notify({ message: "Erreur-Compte n'a pas pu etre modifié", type: "error" })

            }).finally(() => {
                this.updateForm.isLoading = false
                this.updateForm.isUpdate =false;
                this.updateForm.fields ={
                    description:null,
                    code:null,
                    name:null
                }

            })
        },

    },
    mounted() {

        this.getChantiers()
     
    }
}
</script>