<template>
    <AppLayout>
        <main id="main" class="main">

            <section class="section">
                <BreadCrumb
                    :previous="[
                        { label: 'Accueil', goTo: { name: 'Dashboard' } }, { label: 'Agents', goTo: { name: 'AgentListe' } }]"
                    title="Créer compte" />
                <!-- End Page Title -->
                <div class="row">


                    <div class="row">


                        <div class="  col-md-12">

                            <div class="card">
                                <div class="card-body">
                                    <div class="card-title">Compte Agent</div>
                                    <h6 class="card-subtitle mb-4 text-muted">Ce compte sera utile pour le pointage
                                        depuis l'application mobile</h6>
                                    <div v-if="formCreate.isLoading"
                                        style="min-height: 50vh;flex-direction: column;align-items: center;justify-content: center;display: flex">
                                        <div class="spinner-grow text-warning" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                    <div class="alert alert-danger  fade show" role="alert"
                                        v-else-if="formCreate.showMessage && !formCreate.isSuccess">
                                        <h4 class="alert-heading">Erreur </h4>
                                        <p></p>
                                        <hr>
                                        <p class="mb-0">{{ formCreate.message }}</p>
                                    </div>
                                    <form class="row g-3" @submit.prevent="handleCreate" v-if="!formCreate.isLoading">
                                        <div class="col-md-12">
                                            <div class="form-floating">
                                                <input type="text" class="form-control" id="floatingEmail"
                                                    placeholder="Your Email" required
                                                    v-model="formCreate.fields.matricule">
                                                <label for="floatingEmail">Matricule</label>
                                            </div>
                                        </div>
                                        <div class="col-md-12 ">
                                            <div class="form-floating">
                                                <input type="text" class="form-control" id="floatingName"
                                                    placeholder="Your Name" v-model="formCreate.fields.firstname"
                                                    required>
                                                <label for="floatingName">Nom</label>
                                            </div>
                                        </div>
                                        <div class="col-md-12 ">
                                            <div class="form-floating">
                                                <input type="text" class="form-control" id="floatingName"
                                                    placeholder="Your Name" v-model="formCreate.fields.lastname"
                                                    required>
                                                <label for="floatingName">Prenoms</label>
                                            </div>
                                        </div>




                                        <div class="col-md-12">
                                            <div class="form-floating">

                                                <div v-if="chantiers.isLoading" class="spinner-grow text-warning"
                                                    role="status">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div>

                                                <div class="alert alert-danger  fade show" role="alert"
                                                    v-else-if="chantiers.showMessage && !chantiers.isSuccess">
                                                    <h4 class="alert-heading">Erreur </h4>
                                                    <p></p>
                                                    <hr>
                                                    <p class="mb-0">{{ chantiers.message }}</p>
                                                </div>

                                                <select class="form-select" v-else id="floatingSelect"
                                                    aria-label="Depar" v-model="formCreate.fields.chantier_id">
                                                    <option v-for="(dept, i) in chantiers.result" :key="i"
                                                        :value="dept.id">{{ dept.name }}</option>

                                                </select>
                                                <label for="floatingSelect">Centre de coût</label>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-floating">

                                                <div v-if="departements.isLoading" class="spinner-grow text-warning"
                                                    role="status">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div>

                                                <div class="alert alert-danger  fade show" role="alert"
                                                    v-else-if="departements.showMessage && !departements.isSuccess">
                                                    <h4 class="alert-heading">Erreur </h4>
                                                    <p></p>
                                                    <hr>
                                                    <p class="mb-0">{{ departements.message }}</p>
                                                </div>

                                                <select class="form-select" v-else id="floatingSelect"
                                                    aria-label="Depar" v-model="formCreate.fields.departement_id">
                                                    <option v-for="(dept, i) in departements.result" :key="i"
                                                        :value="dept.id">{{ dept.name }}</option>

                                                </select>
                                                <label for="floatingSelect">Services</label>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-floating">

                                                <div v-if="profils.isLoading" class="spinner-grow text-warning"
                                                    role="status">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div>

                                                <div class="alert alert-danger  fade show" role="alert"
                                                    v-else-if="profils.showMessage && !profils.isSuccess">
                                                    <h4 class="alert-heading">Erreur </h4>
                                                    <p></p>
                                                    <hr>
                                                    <p class="mb-0">{{ profils.message }}</p>
                                                </div>

                                                <select class="form-select" v-else id="floatingSelect"
                                                    aria-label="Depar" v-model="formCreate.fields.profil_id">
                                                    <option v-for="(dept, i) in profils.result" :key="i"
                                                        :value="dept.id">{{ dept.name }}</option>

                                                </select>
                                                <label for="floatingSelect">Poste</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-floating">
                                                <input type="email" class="form-control" id="floatingEmail"
                                                    placeholder="Your Email" v-model="formCreate.fields.email" required>
                                                <label for="floatingEmail">Email</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-floating">
                                                <select class="form-select" id="floatingEmail" aria-label="State"
                                                    v-model="formCreate.fields.genre">
                                                    <option>HOMME</option>
                                                    <option>FEMME</option>

                                                </select>
                                                <label for="floatingEmail">Genre</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-floating">
                                                <input type="datetime-local" class="form-control" id="floatingEmail"
                                                    placeholder="Your Email" v-model="formCreate.fields.date_embauche">
                                                <label for="floatingEmail">Date d'embauche</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-floating">
                                                <input type="date" class="form-control" id="floatingEmail"
                                                    placeholder="Your Email" v-model="formCreate.fields.birth">
                                                <label for="floatingEmail">Date de naissance</label>
                                            </div>
                                        </div>


                                        
                                        <div class="col-md-6">
                                            <div class="form-floating mb-3">
                                                <select class="form-select" id="floatingSelect" aria-label="State"
                                                    v-model="formCreate.fields.role" readonly disabled>
                                                    <option>{{ formCreate.fields.role }}</option>

                                                </select>
                                                <label for="floatingSelect">Role</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-floating">
                                                <select class="form-select" id="floatingSelect" aria-label="State"
                                                    v-model="formCreate.fields.is_enroll" readonly disabled>
                                                    <option value="1">Oui</option>
                                                    <option value="0">Non</option>

                                                </select>
                                                <label for="floatingSelect">Est enrolé?</label>
                                            </div>
                                        </div>



                                        <div class="text-center">
                                            <button type="submit" class="btn btn-primary" ><i
                                                    class="bi bi-save  me-1"></i>Créer le compte</button>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </section>


        </main>
    </AppLayout>
</template>
<script>
import { notify } from '@/utils/utils';
import AppLayout from '../../components/Layouts/AppLayout.vue';
import BreadCrumb from '../../components/Layouts/BreadCrumb.vue';
import fetch from '../../utils/fetch';

export default {
    name: "CreateAgent",
    components: {
        AppLayout,
        BreadCrumb
    },
    data() {
        return {

            isLoading: false,
            isSuccess: true,
            showMessage: false,
            message: "",
            result: null,

            selectPointage: null,
            user: null,
            departements: {
                isLoading: false,
                isSuccess: true,
                showMessage: false,
                message: "",
                result: null,
            },
            profils: {
                isLoading: false,
                isSuccess: true,
                showMessage: false,
                message: "",
                result: null,
            },
            chantiers: {
                isLoading: false,
                isSuccess: true,
                showMessage: false,
                message: "",
                result: null,
            },
            formCreate: {
                isLoading: false,
                isSuccess: true,
                showMessage: false,
                message: "",
                result: null,
                fields: {
                    matricule: null,
                    firstname: null,
                    departement_id: null,
                    profil_id: null,
                    email: null,
                    genre: "HOMME",
                    date_embauche:null,
                    birth: null,
                    role: "WORKER",
                    chantier_id: null,
                    password: "okokokokokok",
                    password_confirmation: "okokokokokok",
                    is_enroll: 0


                },
                requiredFields: ["firstname", "lastname", "departement_id", "profil_id", "email", "genre", "date_embauche", "birth", "role", "matricule","password"],
            }

        }
    },
    computed: {

    },
    methods: {

        getProfils() {
            this.profils.isLoading = true;
            this.profils.showMessage = false;

            fetch({ url: `profil`, method: "GET" }).then((data) => {
                this.profils.result = data.content ?? data.data ?? data

                this.profils.isSuccess = true
            }).catch((err) => {
                this.profils.isSuccess = false;
                this.profils.showMessage = true;
                this.profils.message = `${err?.response?.data?.message ?? err}`
                this.profils.result = null
            }).finally(() => {
                this.profils.isLoading = false
            })
        },
        getChantiers() {
            this.chantiers.isLoading = true;
            this.chantiers.showMessage = false;

            fetch({ url: `chantier`, method: "GET" }).then((data) => {
                this.chantiers.result = data.content ?? data.data ?? data

                this.chantiers.isSuccess = true
            }).catch((err) => {
                this.chantiers.isSuccess = false;
                this.chantiers.showMessage = true;
                this.chantiers.message = `Erreur - ${err?.response?.data?.message ?? err}`
                this.chantiers.result = null
            }).finally(() => {
                this.chantiers.isLoading = false
            })
        },
        getDepartements() {
            this.departements.isLoading = true;
            this.departements.showMessage = false;

            fetch({ url: `departement`, method: "GET" }).then((data) => {
                this.departements.result = data.content ?? data.data ?? data

                this.departements.isSuccess = true
            }).catch((err) => {
                this.departements.isSuccess = false;
                this.departements.showMessage = true;
                this.departements.message = `Erreur - ${err?.response?.data?.message ?? err}`
                this.departements.result = null
            }).finally(() => {
                this.departements.isLoading = false
            })
        },



        handleCreate() {
            this.formCreate.isLoading = true;
            this.formCreate.showMessage = false;
            let data = {};
            this.formCreate.requiredFields.forEach((field) => {
                data[field] = this.formCreate.fields[field]
            })


            fetch({ url: `users`, method: "POST", data }).then((data) => {
                let result = data.content ?? data.data ?? data

                this.formCreate.isSuccess = true
                this.$router.push({ name: "AgentShow", params: { id: result.id } })

                notify({ message: "Compte crée", type: "success" })
            }).catch((err) => {
                this.formCreate.isSuccess = false;
                this.formCreate.showMessage = true;
                let message = `${err?.response?.data?.message ?? err}`
                this.formCreate.message = message;
                notify({ message:"Erreur-Compte non enregistré", type: "error" })

            }).finally(() => {
                this.formCreate.isLoading = false

            })
        },

    },
    mounted() {

        this.getChantiers()
        this.getProfils()
        this.getDepartements()
    }
}
</script>