<template >
     <main>
    <div class="container">

      <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-5 col-md-6 d-flex flex-column align-items-center justify-content-center">

              <div class="d-flex justify-content-center py-4">
                <a href="index.html" class="logo d-flex align-items-center w-auto">
                  <img src="assets/img/logo.png" alt="">
                  <span class="d-none d-lg-block">Afrique Conseil</span>
                </a>
              </div><!-- End Logo -->

              <div class="card mb-3">

                <div class="card-body">
                  
                  <div class="pt-4 pb-2">
                    <h5 class="card-title text-center pb-0 fs-4">Connexion à votre compte </h5>
                    <p class="text-center small">Entrez votre nom d'utilisateur et votre mot de passe pour vous connecter</p>
                  </div>

                  <div class="alert alert-danger mt-2  fade show" role="alert" v-if="showMessage && !isSuccess">
                        <h4 class="alert-heading">Oups Erreur </h4>
                        <p></p>
                        <hr>
                        <p class="mb-0">{{message}}</p>
                    </div>
                  <form class="row g-3 needs-validation" validate @submit.prevent="handleSubmit">

                    <div class="col-12">
                      <label for="yourUsername" class="form-label">Email</label>
                      <div class="input-group has-validation">
                        <span class="input-group-text" id="inputGroupPrepend">
                            <i class="bi bi-person"></i>
                        </span>
                        <input type="email" name="username" class="form-control"  v-model="fields.email" id="yourUsername" required>
                        <div class="invalid-feedback">Entrez un email valide</div>
                      </div>
                    </div>

                    <div class="col-12">
                      <label for="yourPassword" class="form-label" >Mot de passe</label>
                      <input type="password" name="password" v-model="fields.password" class="form-control" id="yourPassword" required>
                      <div class="invalid-feedback">Entrez un mot de passe!</div>
                    </div>

                   
                    <div class="col-12">
                      <button class="btn btn-primary w-100" type="submit" :disabled="isLoading">{{ isLoading ? "En cours ...":"Connexion" }}</button>
                    </div>
                   
                  </form>

                </div>
              </div>

              

            </div>
          </div>
        </div>

      </section>

    </div>
  </main>
</template>
<script>
import fetch from '@/utils/fetch';
import { logoutOut } from '../../utils/fetch';

export default {
    name:"LoginView",
    data(){
      return {
        isLoading:false,
        isSuccess:false,
        message:"",
        showMessage:false,
        fields:{
          password:null,
          email:null
        }
      }
    },
    methods:{
      handleSubmit(){
        this.isLoading = true;
        this.showMessage = false;
        
        fetch({method:"POST",url:"login",data:this.fields}).then(({content})=>{
         
          localStorage.setItem("token",content.token)
          this.$router.push({name:"Dashboard"})
        }).catch((err)=>{
         this.showMessage = true;
         this.isSuccess =false;
         this.message =err?.response?.data?.message ?? err
        
        }).finally(()=>{
          this.fields.email = null;
          this.fields.password=null;
          this.isLoading = false

        })
      },
      logoutOut
    },
    mounted(){
      logoutOut()
    }
    
}
</script>