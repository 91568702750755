<template>
    <AppLayout>
        <main id="main" class="main">

            <BreadCrumb :previous="[{ label: 'Accueil', goTo: '/' }]" title="Tableau de bord" />
            <!-- End Page Title -->

            <section class="section dashboard">
                <div class="row">
                    <div v-if="isLoading"
                        style="min-height: 50vh;flex-direction: column;align-items: center;justify-content: center;display: flex">


                        <div class="spinner-grow text-warning" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>

                    </div>
                    <div class="alert alert-danger  fade show" role="alert" v-else-if="showMessage">
                        <h4 class="alert-heading">Oups Erreur </h4>
                        <p></p>
                        <hr>
                        <p class="mb-0">{{ message }}</p>
                    </div>


                    <div class="col-lg-12" v-else-if="isSuccess && result">
                        <div class="row">

                            <!-- Sales Card -->
                            <div class="col-xxl-6 col-md-6">
                                <div class="card info-card sales-card">



                                    <div class="card-body">
                                        <h5 class="card-title">Nbre de Pointage <span>| Aujourd'hui</span></h5>

                                        <div class="d-flex align-items-center">
                                            <div
                                                class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                <i class="bi bi-person-check"></i>
                                            </div>
                                            <div class="ps-3">
                                                <h6>{{ result?.todayCount ?? "-" }} Aujourd'hui</h6>
                                                <span class="text-success small pt-1 fw-bold"> au lieu de {{
                                                    result?.yesterdayCount
                                                    ?? "-"}}</span> <span class="text-muted small pt-2 ps-1">hier</span>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div><!-- End Sales Card -->

                            <!-- End Revenue Card -->

                            <!-- Customers Card -->
                            <div class="col-xxl-6 col-md-6">

                                <div class="card info-card customers-card">



                                    <div class="card-body">
                                        <h5 class="card-title">Utilisateurs</h5>

                                        <div class="d-flex align-items-center">
                                            <div
                                                class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                <i class="bi bi-people"></i>
                                            </div>
                                            <div class="ps-3">
                                                <h6>{{ result?.userCount ?? "-" }} </h6>
                                                <span class="text-success small pt-1 fw-bold">utilisateurs</span>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div><!-- End Customers Card -->


                            <!-- Recent Sales -->
                            <div class="col-12">
                                <div class="card recent-sales overflow-auto">



                                    <div class="card-body">
                                        <h5 class="card-title">Pointages <span>| Aujourd'hui</span></h5>


                                        <vue3-datatable :rows="result.pointings" :loading="isLoading" :columns="cols"
                                            noDataContent="Vide" paginationInfo="De {0} à {1} sur {2} "
                                            :columnFilterLang="colsLang" :columnFilter="true">
                                            <template #actions="data">
                                                <div style="display: flex;justify-content: start;">


                                                    <router-link
                                                        :to="{ name: 'PointageShow', params: { id: data.value.id } }"
                                                        type=" button" class="btn btn-secondary mx-1"><i
                                                            class="bi bi-eye"></i></router-link>

                                                </div>

                                            </template>
                                        </vue3-datatable>

                                    </div>

                                </div>
                            </div><!-- End Recent Sales -->

                        </div>
                    </div>
                </div>
            </section>

        </main>
    </AppLayout>
</template>
<script>
import AppLayout from '../components/Layouts/AppLayout.vue';
import BreadCrumb from '../components/Layouts/BreadCrumb.vue';
import fetch from '../utils/fetch';
import { dateFromNow } from '../utils/utils';
import Vue3Datatable from '@bhplugin/vue3-datatable'



export default {
    name: "DashboardView",
    components: {
        BreadCrumb,
        AppLayout,
        Vue3Datatable

    },
    data() {
        return {
            cols:
                [{
                    field: "worker",
                    cellRenderer: ({ worker }) => {

                        if (!worker) {
                            return ""
                        }
                        return `<b>${worker.firstname + " " + worker.lastname}</b>`
                    },
                    title: "Agent",
                    type: "",

                    filter: true
                }, {
                    field: "date",
                    title: "Moment",filter:false,
                    cellRenderer: (point) => {
                        return dateFromNow(point.rigth_date);
                    },

                }, {
                    field: "date",
                    title: "Date",

                    filter: true,
                    type: "date",
                },
                {
                    field: "hours",
                    title: "Heure de Pointage",
                    filter: true,
                }, {
                    field: "",
                    cellRenderer: (point) => {
                        return `<span class="${point.isLate ? 'badge bg-warning' : 'badge bg-success'}">${point.isLate
                            ? 'Oui' : 'Non'}</span>`;
                    },
                    title: "En retard ?",
                    type: "",
                    filter: false,
                    sort: false,
                }, {
                    field: "actions",
                    title: "Actions",
                    type: "",
                    filter: false,
                    sort: false,
                },
                ],
            colsLang: { no_filter: 'Aucun', contain: 'Contiens', not_contain: 'Ne contiens pas', equal: 'Egale', not_equal: 'Différent', start_with: 'Commence par', end_with: 'Termine par', greater_than: 'Supérieur à', greater_than_equal: 'Sup. ou égale à', less_than: 'Inférieur à', less_than_equal: 'Inf. ou égale à', is_null: 'Est null', is_not_null: 'Non null' },

            isLoading: false,
            isSuccess: true,
            showMessage: false,
            message: "",
            result: {
                pointings: []
            }
        }
    },

    methods: {
        dateFromNow,
        getInfosDashoboard() {
            this.isLoading = true;
            this.showMessage = false

            fetch({ url: "dashboard/info", }).then((data) => {
                console.log('data', data)
                this.result = data.content ?? data
                this.isSuccess = true
            }).catch((err) => {
                this.isSuccess = false;
                this.showMessage = true;
                this.message = `Erreur - ${err?.response?.data?.message ?? err}`
                this.result = null
            }).finally(() => {
                this.isLoading = false
            })
        }
    },
    mounted() {
        this.getInfosDashoboard()
    }

}
</script>
<style scoped>
.bh-datatable .bh-table-responsive {
    min-height: 300px;
}
</style>