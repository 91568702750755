import { createRouter, createWebHistory } from 'vue-router'

import Login from '../views/auth/Login.vue'

import Dashboard from '../views/Dashboard.vue'

//Pointage
import ListePointage from '../views/pointages/List.vue'
import EditPointage from '../views/pointages/Edit.vue'
import ShowPointage from '../views/pointages/Show.vue'
import ExportPointage from '../views/pointages/Export.vue'

//Pointeurs
import ListePointeur from '../views/pointeurs/List.vue'
import EditPointeur from '../views/pointeurs/Edit.vue'
import ShowPointeur from '../views/pointeurs/Show.vue'
import CreatePointeur from '../views/pointeurs/Create.vue'


//Agents
import ListeAgent from '../views/agents/List.vue'
import EditAgent from '../views/agents/Edit.vue'
import ShowAgent from '../views/agents/Show.vue'
import CreateAgent from '../views/agents/Create.vue'


//Configurations

//Chantier
import ListeChantier from '../views/configuration/chantiers/List.vue'

// Departement
import ListDepartement from '../views/configuration/departements/List.vue'

// Profil
import ListProfil from '../views/configuration/profils/List.vue'


//Admin
import ListeAdmin from '../views/admin/List.vue'
import EditAdmin from '../views/admin/Edit.vue'
import ShowAdmin from '../views/admin/Show.vue'
import CreateAdmin from '../views/admin/Create.vue'

//Report
// import Report from '../views/Report.vue'


import { loadLayoutMiddleware } from './layoutLoader'
import MonCompte from '../views/MonCompte.vue'

const routes = [

    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
            layout: "TemplateDefault"
        }
    },
    {
        path: '/',
        component: Dashboard,
        name: "Dashboard",
        meta: {
            layout: "TemplateAuth"
        }
    },
    {
        path: '/report',
        component: ExportPointage,
        name: "Report",
        meta: {
            layout: "TemplateAuth"
        }
    },
    {
        path: '/pointage',
        name: "PointageListe",
        component: ListePointage,
        meta: {
            layout: "TemplateAuth"
        }
    }, 
    {
        path: "/pointage/filtre",
        name: "PointageExportation",
        component: ExportPointage,
        meta: {
            layout: "TemplateAuth"
        },
    },
    {
        path: "/pointage/:id/show",
        name: "PointageShow",
        component: ShowPointage,
        meta: {
            layout: "TemplateAuth"
        },
    },
    {
        path: "/pointage/:id/edit",
        name: "PointageEdit",
        component: EditPointage,
        meta: {
            layout: "TemplateAuth"
        },
    },
    {
        path: '/pointeur',
        name: "PointeurListe",
        component: ListePointeur,
        meta: {
            layout: "TemplateAuth"
        }
    }, 
    
    {
        path: "/pointeur/create",
        name: "PointeurCreate",
        component: CreatePointeur,
        meta: {
            layout: "TemplateAuth"
        },
    },
    {
        path: "/pointeur/:id/edit",
        name: "PointeurEdit",
        component: EditPointeur,
        meta: {
            layout: "TemplateAuth"
        },
    },
    {
        path: "/pointeur/:id/show",
        name: "PointeurShow",
        component: ShowPointeur,
        meta: {
            layout: "TemplateAuth"
        },
    },
    {
        path: '/agent',
        name: "AgentListe",
        component: ListeAgent,
        meta: {
            layout: "TemplateAuth"
        }
    }, 
    
    {
        path: "/agent/create",
        name: "AgentCreate",
        component: CreateAgent,
        meta: {
            layout: "TemplateAuth"
        },
    },
    {
        path: "/agent/:id/edit",
        name: "AgentEdit",
        component: EditAgent,
        meta: {
            layout: "TemplateAuth"
        },
    },
    {
        path: "/agent/:id/show",
        name: "AgentShow",
        component: ShowAgent,
        meta: {
            layout: "TemplateAuth"
        },
    },
    {
        path: '/admin',
        name: "AdminListe",
        component: ListeAdmin,
        meta: {
            layout: "TemplateAuth"
        }
    }, 
    
    {
        path: "/admin/create",
        name: "AdminCreate",
        component: CreateAdmin,
        meta: {
            layout: "TemplateAuth"
        },
    },
    {
        path: "/admin/:id/edit",
        name: "AdminEdit",
        component: EditAdmin,
        meta: {
            layout: "TemplateAuth"
        },
    },
    {
        path: "/admin/:id/show",
        name: "AdminShow",
        component: ShowAdmin,
        meta: {
            layout: "TemplateAuth"
        },
    },
    {
        path: "/configuration/chantier",
        name: "ChantierListe",
        component: ListeChantier,
        meta: {
            layout: "TemplateAuth"
        },
    },
    {
        path: "/configuration/departement",
        name: "DepartementListe",
        component: ListDepartement,
        meta: {
            layout: "TemplateAuth"
        },
    },
    {
        path: "/configuration/poste",
        name: "PosteListe",
        component: ListProfil,
        meta: {
            layout: "TemplateAuth"
        },
    },
    {
        path: "/moncompte",
        name: "MonCompte",
        component: MonCompte,
        meta: {
            layout: "TemplateAuth"
        },
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

router.beforeEach(loadLayoutMiddleware)
export default router;