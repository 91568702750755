<template>
    <AppLayout>
        <main id="main" class="main">


            <BreadCrumb :previous="[
                { label: 'Accueil', goTo: { name: 'Dashboard' } }]" title="Rapport" />

            <section class="section">
                <div class="row">
                    <div class="col-lg-12">

                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">Rapport</h5>


                                <!-- General Form Elements -->
                                <form @submit.prevent="handleFilter">
                                    <div v-if="chantiers.isLoading || departements.isLoading || workers.isLoading"
                                        style="min-height: 15vh;flex-direction: column;align-items: center;justify-content: center;display: flex">


                                        <div class="spinner-grow text-warning" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>

                                    </div>
                                    <div class="alert alert-danger  fade show" role="alert"
                                        v-else-if="!chantiers.isSuccess || !departements.isSuccess || !workers.isSuccess">
                                        <h4 class="alert-heading">Oups Erreur </h4>
                                        <p></p>
                                        <hr>
                                        <p class="mb-0">{{ message }}</p>
                                    </div>
                                    <div class="row mb-3" v-else>


                                        <div class="col-md-6">
                                            <div class="form-floating mb-3" v-if="chantiers.isSuccess">

                                                <multiselect id="floatingSelect"
                                                    aria-label="Floating label select example"
                                                    v-model="filterData.fields.chantiers" :options="chantiers.data"
                                                    placeholder="Centre de Coût" track-by="name" label="name"
                                                    taggin="id" :multiple="true"></multiselect>
                                                <!-- <label for="floatingSelect">Chantiers</label> -->

                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-floating mb-3" v-if="departements.isSuccess">

                                                <multiselect id="floatingSelect"
                                                    aria-label="Floating label select example"
                                                    v-model="filterData.fields.departements"
                                                    :options="departements.data" placeholder="Service" track-by="name"
                                                    label="name" taggin="id" :multiple="true">
                                                </multiselect>

                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-floating mb-3" v-if="workers.isSuccess">

                                                <multiselect id="floatingSelect"
                                                    aria-label="Floating label select example"
                                                    v-model="filterData.fields.workers" :options="workers.data"
                                                    placeholder="Agents"
                                                    :custom-label="(option, label) => { return option.lastname + ' ' + option.firstname }"
                                                    :multiple="true">
                                                </multiselect>

                                            </div>
                                        </div>
                                        <div class="col-md-6">

                                            <VueDatePicker class="w-full" v-model="filterData.fields.dateBetween"
                                                placeholder="Du - au -" range :max-date="new Date()"></VueDatePicker>

                                        </div>

                                    </div>


                                    <div class="d-flex justify-content-end "
                                        v-if="chantiers.isSuccess && departements.isSuccess && workers.isSuccess">

                                        <button type="submit" class="btn btn-primary">Appliquer</button>

                                    </div>

                                </form><!-- End General Form Elements -->

                            </div>
                        </div>

                    </div>


                </div>
                <div v-if="filterData.isLoading"
                    style="min-height: 50vh;flex-direction: column;align-items: center;justify-content: center;display: flex">


                    <div class="spinner-grow text-warning" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>

                </div>
                <div class="alert alert-danger  fade show" role="alert" v-else-if="filterData.showMessage">
                    <h4 class="alert-heading">Oups Erreur </h4>
                    <p></p>
                    <hr>
                    <p class="mb-0">{{ filterData.message }}</p>
                </div>


                <div class="col-lg-12" v-else-if="filterData.isSuccess && filterData.result">
                    <div class="row">



                        <!-- Recent Sales -->
                        <div class="col-12">
                            <div class="card recent-sales overflow-auto">



                                <div class="card-body">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <h3 class="card-title">Pointages </h3>
                                        <button type="button" style="height: fit-content;" class="btn btn-success"
                                            data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top"
                                            @click="exportToExcel"><i class="bi bi-excel me-1"></i>
                                            Excel
                                        </button>
                                    </div>
                                    <vue3-datatable :rows="filterData.result" :columns="cols"
                                        noDataContent="Vous n'avez pas de pointage qui repond aux criteres de filtre"
                                        paginationInfo="De {0} à {1} sur {2} " :columnFilterLang="colsLang"
                                        :columnFilter="true" sortColumn="Date">
                                        <template #actions="data">
                                            <div style="display: flex;justify-content: start;">


                                                <router-link
                                                    :to="{ name: 'PointageShow', params: { id: data.value.id } }"
                                                    type=" button" class="btn btn-secondary mx-1"><i
                                                        class="bi bi-eye"></i></router-link>

                                            </div>

                                        </template>
                                    </vue3-datatable>

                                </div>

                            </div>
                        </div><!-- End Recent Sales -->


                    </div>
                </div>
            </section>

        </main>
    </AppLayout>
</template>
<script>
import Multiselect from 'vue-multiselect';
import AppLayout from '../../components/Layouts/AppLayout.vue';
import BreadCrumb from '../../components/Layouts/BreadCrumb.vue';
import { getChantierApi, getWorkerApi, getDepartementApi } from '../../utils/api';
import Vue3Datatable from '@bhplugin/vue3-datatable'

import "@/assets/vue-multiselect.css"
import { dateFromNow } from '../../utils/utils';
import fetch from '../../utils/fetch';
import * as XLSX from 'xlsx';


//you need to import the CSS manually
// import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'


export default {
    name: "ExportPointage",
    components: {
        AppLayout,
        BreadCrumb,
        Multiselect,
        Vue3Datatable,

    },
    data() {
        const currentDate = new Date();
        let startDate = `${currentDate.getFullYear()}-${("0" + (currentDate.getMonth() + 1)).slice(-2)}-${("0" + currentDate.getDate()).slice(-2)}T00:00:00`
        let endDate = startDate.replace("T00:00:00", "T23:59:59")

        return {
            filterData: {
                isLoading: false,
                isSuccess: false,
                showMessage: false,
                message: "",
                fields: {
                    dateBetween: [startDate, endDate],
                    chantier_id: null,
                    workers: [],
                    departements: [],
                    chantiers: []

                },
                result: []
            },
            chantiers: {
                isLoading: false,
                isSuccess: false,
                showMessage: false,
                message: "",
                data: null
            },
            departements: {
                isLoading: false,
                isSuccess: false,
                showMessage: false,
                message: "",
                data: null
            },
            workers: {
                isLoading: false,
                isSuccess: false,
                showMessage: false,
                message: "",
                data: null
            },
            cols:
                [{
                    field: "worker",
                    cellRenderer: ({ worker }) => {

                        if (!worker) {
                            return ""
                        }
                        return `<b>${worker.firstname + " " + worker.lastname}</b>`
                    },
                    title: "Agent",
                    type: "",

                    filter: true
                }, {
                    field: "date",
                    title: "Moment", filter: false,
                    cellRenderer: (point) => {
                        return dateFromNow(point.rigth_date);
                    },

                }, {
                    field: "date",
                    title: "Date",

                    filter: true,
                    type: "date",
                },
                {
                    field: "hours",
                    title: "Heure de Pointage",
                    filter: true,
                }, {
                    field: "",
                    cellRenderer: (point) => {
                        return `<span class="${point.isLate ? 'badge bg-warning' : 'badge bg-success'}">${point.isLate
                            ? 'Oui' : 'Non'}</span>`;
                    },
                    title: "En retard ?",
                    type: "",
                    filter: false,
                    sort: false,
                }, {
                    field: "actions",
                    title: "Actions",
                    type: "",
                    filter: false,
                    sort: false,
                },
                ],
            colsLang: { no_filter: 'Aucun', contain: 'Contiens', not_contain: 'Ne contiens pas', equal: 'Egale', not_equal: 'Différent', start_with: 'Commence par', end_with: 'Termine par', greater_than: 'Supérieur à', greater_than_equal: 'Sup. ou égale à', less_than: 'Inférieur à', less_than_equal: 'Inf. ou égale à', is_null: 'Est null', is_not_null: 'Non null' },


        }
    },
    methods: {
        dateFromNow,

        async getChantiers() {
            this.chantiers.isLoading = true;
            this.chantiers = await getChantierApi();
        },
        async getWorkers() {
            this.workers.isLoading = true;
            this.workers = await getWorkerApi();
        },
        async getDepartements() {
            this.departements.isLoading = true;
            this.departements = await getDepartementApi();
        },
        handleFilter() {
            this.filterData.isLoading = true;
            this.filterData.showMessage = false
            const data = {}
            if (this.filterData.fields.chantiers.length) {
                data.chantiers = this.filterData.fields.chantiers.map(({ id }) => id);
            }
            if (this.filterData.fields.departements.length) {
                data.departements = this.filterData.fields.departements.map(({ id }) => id);
            }
            if (this.filterData.fields.workers.length) {
                data.workers = this.filterData.fields.workers.map(({ id }) => id);
            }
            if (this.filterData.fields.dateBetween) {
                data.dateBetween = this.filterData.fields.dateBetween
            }

            fetch({ url: "pointing/filter", method: "POST", data }).then((data) => {

                this.filterData.result = data.content ?? data.data ?? data
                this.filterData.isSuccess = true
            }).catch((err) => {
                this.filterData.isSuccess = false;
                this.filterData.showMessage = true;
                this.filterData.message = `Erreur - ${err}`
                this.filterData.result = null
            }).finally(() => {
                this.filterData.isLoading = false
            })
        },




        exportToExcel() {
            this.filterData.isLoading = true;
            const resulta = this.sortPointing()

            // const exportData = [];
            const allSheet = Object.keys(resulta)

            const workbook = XLSX.utils.book_new();
            allSheet.forEach(sheetname => {


                const onePointing = resulta[sheetname];
                const usersPointings = Object.keys(onePointing);

                let exportData = [];
                usersPointings.forEach((userPoints) => {
                    const listPointings = onePointing[userPoints];
                    console.log('listPointings', listPointings)
                    // let hours = ""
                    let name = ""
                    let chantier = ""
                    let rigth_date = ""
                    let commentaire = ""
                    let date = ""
                    let hour_depart = "-"
                    let hour_depart_prec = "-"
                    let hour_arrive = "-"
                    let hour_arrive_prec = "-"
                    let diff = "-"

                    listPointings.forEach((poin,i) => {
                        if(i ==0){
                            hour_arrive = poin.hours
                            hour_arrive_prec = poin?.rigth_date
                           
                        }else if(i==1){
                            hour_depart = poin.hours
                            hour_depart_prec = poin?.rigth_date
                        }
                        // hours +=  + "- "
                        name = poin?.worker?.firstname + " " + poin?.worker?.lastname

                        chantier = poin?.chantier?.name ?? "Non definie"
                      
                        date += poin?.date 
                        commentaire += !poin.commentaire ? "": poin?.commentaire+ " \t \n "
                        if(i== 1){
                            if(hour_arrive_prec != "-"  && hour_depart_prec != "-")
                            var timeStart = new Date(hour_depart_prec).getHours();
                            var timeEnd = new Date(hour_arrive_prec).getHours();
                            diff = Math.abs(timeEnd -timeStart)
                            stop()
                        }

                    })


                    exportData.push({
                        "Matricule": userPoints,
                        "Nom & Prénoms": name,
                        "Cente de cout": chantier,
                        "Date": date,
                        "Heure D'arriver": hour_arrive,
                        "Heure De Départ": hour_depart,
                        "Heure D'arriver Precise": hour_arrive_prec,
                        "Heure De Départ Precise": hour_depart_prec,
                        "Nombre d'heure":diff,
                        "Date Precise": rigth_date,
                        "Commentaire": commentaire,

                    })
                })
                let worksheet = XLSX.utils.json_to_sheet(exportData);

                XLSX.utils.book_append_sheet(workbook, worksheet, `Pointage_${sheetname.replaceAll("/", "_")}`);





            });



            // const worksheet = XLSX.utils.json_to_sheet(exportData);



            // // Créer un fichier Excel et le télécharger
            const fileName = `report_${Date.now()}.xlsx`;
            XLSX.writeFile(workbook, fileName);

            this.filterData.isLoading = false;



            // console.log('this.filterData.result', this.filterData.result)
            // const exportData=[];

            // this.filterData.result.forEach(({convert_date,rigth_date,hours,date,type,commentaire,chantier,worker,saveby,updateby})=>{
            //     exportData.push({
            //         "Matricule":worker.matricule,
            //         "Nom & Prénoms":worker.firstname+" "+worker.lastname,
            //         "Type de Pointage":type,
            //         "Lieu de Pointage":chantier.name ?? "Non definie",
            //         "Date":date,
            //         "Heure":hours,
            //         "Date et Heure arrondi":convert_date,
            //         "Date Precise":rigth_date,
            //         "Modifier par":updateby ? updateby?.firstname+" "+updateby?.lastname :" ",
            //         "Commentaire":commentaire,
            //         "Enregistré par":saveby ? saveby?.firstname+" "+saveby?.lastname : " "

            //     })
            // })


            // // Récupérer les données et préparer l'export
            // const worksheet = XLSX.utils.json_to_sheet(exportData);


            // const workbook = XLSX.utils.book_new();
            // XLSX.utils.book_append_sheet(workbook, worksheet, 'Rapport');
        },
        sortPointing() {
            const sorted = {};

            // Grouper les données par date et par worker
            this.filterData.result.forEach(pointing => {
                const date = pointing.date;
                const workerId = pointing.worker.matricule;

                // Si la date n'existe pas encore, on l'initialise
                if (!sorted[date]) {
                    sorted[date] = {};
                }

                // Si le worker n'existe pas encore pour cette date, on l'initialise
                if (!sorted[date][workerId]) {
                    sorted[date][workerId] = [];
                }

                // Ajouter le pointing à la liste correspondante
                sorted[date][workerId].push(pointing);
            });

            // Trier les dates
            const sortedDates = Object.keys(sorted).sort();

            // Réorganiser les données triées dans un tableau
            const result = {};
            sortedDates.forEach(date => {
                result[date] = sorted[date];
            });

            return result;
        }




    },

    mounted() {
        this.getChantiers()
        this.getDepartements()
        this.getWorkers()
        this.handleFilter()
    }
}
</script>