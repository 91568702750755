<template>
    <AppLayout>
        <main id="main" class="main">



            <section class="section">
                <BreadCrumb
                    :previous="[
                        { label: 'Accueil', goTo: { name: 'Dashboard' } }, { label: 'Pointages', goTo: { name: 'PointageListe' } }]"
                    :title="(result?.worker?.firstname ?? '') + ' ' + (result?.worker?.lastname ?? '')" />
                <!-- End Page Title -->
                <div class="row">
                    <div v-if="isLoading"
                        style="min-height: 50vh;flex-direction: column;align-items: center;justify-content: center;display: flex">


                        <div class="spinner-grow text-warning" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>

                    </div>
                    <div class="alert alert-danger  fade show" role="alert" v-else-if="showMessage">
                        <h4 class="alert-heading">Oups Erreur </h4>
                        <p></p>
                        <hr>
                        <p class="mb-0">{{ message }}</p>
                    </div>

                    <div class="row" v-else>
                        <div class="col-xl-6 ">

                            <div class="card">
                                <div class="card-body profile-card pt-4 d-flex flex-column  justify-content-between">

                                    <div class="d-flex align-items-center ">
                                        <img src="@/assets/img/profile-img.jpg" alt="Profile" class="rounded-circle"
                                            style="width: 70px;">
                                        <div class="mx-3">
                                            <div class="">
                                                <router-link
                                                    :to="{ name: 'AgentShow', params: { id: result?.worker.id } }"><b
                                                        style="font-size: 20px;">
                                                        {{ (result?.worker.firstname ?? '') + ' ' +
                                                            (result?.worker.lastname ?? '') }}
                                                    </b>
                                                </router-link>
                                            </div>
                                            <span class="card-title">{{ (result?.worker?.profil?.name ?? '') }} <span>| {{
                                                (result?.worker?.departement?.chantier?.name ?? '') }}</span></span>
                                            <div style="font-size: 13px;font-weight: 200">{{ (result?.worker.matricule
                                                ?? '') }}</div>
                                        </div>
                                    </div>
                                    <div class="social-links mt-5 d-flex justify-content-between">
                                        <div class="d-flex flex-column justify-content-between border-right ml-4"
                                            style="font-size: 13px;">
                                            <span>Dern. Pointage</span>
                                            <b>Aujourd'hui,14h20</b>
                                            <span style="font-size: 10px">par K.Carine</span>
                                        </div>
                                        <div class=" mx-3"
                                            style="min-width: 2px;min-height: 100%;background-color: gray;border-radius: 20px">
                                        </div>
                                        <div class="d-flex flex-column justify-content-between"
                                            style="font-size: 13px;">
                                            <span>Nbre. Pointage</span>
                                            <b>7/7jours</b>
                                            <span style="font-size: 10px">0% d'absence cette semaine</span>
                                        </div>
                                        <div class=" mx-1"
                                            style="min-width: 2px;min-height: 100%;background-color: gray;border-radius: 20px">
                                        </div>
                                        <div class="d-flex flex-column justify-content-between mr-4"
                                            style="font-size: 13px;">
                                            <span>Nbre. Heure</span>
                                            <b>80h </b>
                                            <span style="font-size: 10px">en ce Mois</span>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>




                        <div v-if="selectPointage" class="col-xl-6" style="min-height: 100%;">

                            <div class="card">
                                <div v-if="deleteData.isOpen" class="card-body" style="margin-bottom: 0px  !important;">
                                    <h4 class="alert-heading text-danger mt-4">{{ deleteData.isLoading ?
                                        "Suppression en cour" :
                                        "Attention"
                                        }} </h4>
                                    <p class="text-danger">{{ deleteData.isLoading ?
                                        "Veuillez-patienter,la suppression est en cour" :
                                        "Voulez-vous réellement procéder à la suppression de ces informations"
                                        }}</p>
                                    <hr>
                                    <div v-if="deleteData.isLoading">
                                        <div class="spinner-grow text-danger" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <p class="mb-0 text-danger">
                                            Une fois supprimer,cette information ne sera plus
                                            considérer dans les calculs et statistiques
                                        </p>
                                        <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                                        <div class="d-flex justify-content-end mt-3">
                                            <button type="button" class="btn btn-secondary mx-2"
                                                @click="() => { deleteData.isOpen = false }">Fermer</button>
                                            <button type="button" class="btn btn-danger"
                                                @click="deletePointing">Supprimer</button>
                                        </div>
                                    </div>
                                </div>

                                <div class="card-body" v-else-if="updateData.isOpen">
                                    <h5 class="card-title">
                                        {{ updateData.isLoading ?
                                            "Modification en cour" :
                                            "Modification"
                                        }}
                                        <br>

                                        <b style="font-size: 13px;font-weight: 400;">
                                            {{ updateData.isLoading ?
                                                "La modification en cour,Patientez" :
                                                "Veuillez bien renseigner les champs"
                                            }}
                                        </b>
                                    </h5>

                                    <div v-if="updateData.isLoading">
                                        <div class="spinner-grow text-warning" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>


                                    <!-- Advanced Form Elements -->
                                    <form @submit.prevent="updatePointing" v-else>
                                        <div :class="updateData.isSuccess ? 'alert alert-success  fade show text-md' : 'alert alert-danger  fade show text-md'"
                                            role="alert" v-if="updateData.showMessage">

                                            <h4 v-if="updateData.isSuccess" class="alert-heading">Bravo </h4>
                                            <h4 v-else class="alert-heading">Oups Erreur </h4>

                                            <p class="mb-0">{{ updateData.message }}</p>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-12">

                                                <div class="form-floating mb-3">
                                                    <input type="time" v-model="updateData.fields.hours" required
                                                        class="form-control" id="floatingInput">
                                                    <label for="floatingInput">Heure:</label>
                                                </div>
                                                <div class="form-floating mb-3">
                                                    <input type="date" v-model="updateData.fields.date" required
                                                        class="form-control" id="floatingInput">
                                                    <label for="floatingInput">Date:</label>
                                                </div>
                                                <div class="form-floating mb-3" v-if="chantiers.isSuccess">
                                                    <select class="form-select" required
                                                        v-model="updateData.fields.pointing_at" id="floatingSelect"
                                                        aria-label="Floating label select example">
                                                        <option v-for="(cht, i) in chantiers.data" :key="i"
                                                            :value="cht.id">{{ cht.name }}</option>

                                                    </select>
                                                    <label for="floatingSelect">Centre de coût</label>
                                                </div>
                                                <div class="form-floating mb-3">
                                                    <input type="email" class="form-control" id="floatingInput"
                                                        placeholder="name@example.com" required readonly disabled
                                                        :value="`${updateData.fields.saveby?.firstname+' '+updateData.fields.saveby?.lastname}`">
                                                    <label for="floatingInput">Enrégistre par:</label>
                                                </div>

                                                <div class="form-floating mb-3">
                                                    <textarea class="form-control"
                                                        v-model="updateData.fields.commentaire" required
                                                        placeholder="Leave a comment here" id="floatingTextarea"
                                                        style="height: 150px;"></textarea>
                                                    <label for="floatingTextarea">Commentaire</label>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="d-flex justify-content-end mt-3">
                                            <button type="submit" class="btn btn-primary"
                                                v-if="chantiers.isSuccess">Modifier</button>
                                            <button type="button" class="btn btn-secondary mx-2"
                                                @click="() => { updateData.isOpen = false }">Fermer</button>
                                        </div>

                                    </form>
                                    <!-- End General Form Elements -->

                                </div>

                                <div v-else class="card-body pt-2">
                                    <div class="row mt-3">
                                        <h4 class=" col-xxl-8 col-md-12  order-xxl-1 order-2 label text-primary">
                                            <b>Centre de coût
                                                {{ (selectPointage.chantier?.name ?? " ") }}
                                            </b>
                                        </h4>
                                        <h4
                                            class=" col-xxl-4 col-lg-12 col-md-12 order-xxl-2 order-1 label text-primary">
                                            <button class="btn btn-primary " @click="showUpdateModal">Modifier</button>
                                            <button class="btn btn-danger mx-3"
                                                @click="() => { deleteData.isOpen = true }">Supprimer</button>
                                        </h4>
                                    </div>

                                    <div class="row mt-2">
                                        <div class="col-md-12 label " style="font-weight: 200;font-size: 13px;">
                                            Date de pointage
                                            <span class="ml-2" style="font-weight: 700;font-size: 15px">
                                                : {{ dateFromNow(selectPointage.rigth_date) }} 
                                            </span>
                                        </div>
                                    </div>

                                    <div class="row mt-2">
                                        <div class="col-lg-12 col-md-12 label "
                                            style="font-weight: 200;font-size: 13px;">
                                            Date
                                            <span class="" style="font-weight: 900;font-size: 15px;">
                                                : {{ (selectPointage.date) }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-lg-12 col-md-12 label "
                                            style="font-weight: 200;font-size: 13px;">
                                            Heure
                                            <span class="" style="font-weight: 900;font-size: 15px;">
                                                : {{ (selectPointage.hours) }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-lg-12 col-md-12 label "
                                            style="font-weight: 200;font-size: 13px;">
                                            Par
                                            <span class="" style="font-weight: 900;font-size: 15px;">
                                                : {{ selectPointage.saveby?.firstname+" "+  selectPointage.saveby?.lastname }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-lg-12 col-md-12 label "
                                            style="font-weight: 200;font-size: 13px;">
                                            Heure exacte
                                            <span class="" style="font-weight: 900;font-size: 15px;">
                                                : {{ selectPointage.rigth_date ?? "-" }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="accordion mt-2" id="accordionExample"
                                        v-if="selectPointage.parent?.length">
                                        <div class="col-lg-12 col-md-12 label "
                                            style="font-weight: 200;font-size: 13px;">
                                            Modifications
                                        </div>
                                        <div class="accordion-item" v-for="(updt, i) in selectPointage.updated"
                                            :key="i">
                                            <h2 class="accordion-header" :id="headingOne">
                                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                                    :data-bs-target="'#collapseOne' + i" aria-expanded="true"
                                                    :aria-controls="'collapseOne' + i">
                                                    Modification #{{ i + 1 }}
                                                </button>
                                            </h2>
                                            <div :id="'collapseOne' + i" class="accordion-collapse collapse"
                                                aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <div class="row">
                                                        <div class="col-lg-12 col-md-12 label "
                                                            style="font-weight: 200;font-size: 13px;">
                                                            Par</div>
                                                        <div class="col-lg-12 col-md-12" style="font-weight: 700;">
                                                            {{ (updt.firstname ?? " ") + " " + (updt.lastname ??
                                                                " ") }}
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg-12 col-md-12 label "
                                                            style="font-weight: 200;font-size: 13px;">
                                                            Commentaire</div>
                                                        <div class="col-lg-12 col-md-12"
                                                            style="font-weight: 500;font-size: 14px;">
                                                            {{ updt.commentaire }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>




        </main>
    </AppLayout>
</template>
<script>
import AppLayout from '../../components/Layouts/AppLayout.vue';
import BreadCrumb from '../../components/Layouts/BreadCrumb.vue';
import { getChantierApi } from '../../utils/api';
import fetch from '../../utils/fetch';
import { dateFromNow, hourFormat } from '../../utils/utils';
// import Vue3Datatable from "@bhplugin/vue3-datatable"

export default {
    name: "ShowPointage",
    components: {
        AppLayout,
        BreadCrumb,
        // Vue3Datatable
    },
    data() {
        return {
            isLoading: false,
            isSuccess: true,
            showMessage: false,
            message: "",
            result: null,

            selectPointage: null,
            user: null,
            deleteData: {
                fields: null,
                isOpen: false,
                isSuccess: false,
                showMessage: false,
                message: "",
                isLoading: false,
            },
            updateData: {
                requiredFields: ["hours", "date", "pointing_at", "commentaire","worker_id","type","created_by"],
                fields: null,
                isOpen: false,
                showMessage: false,
                message: "",
                isLoading: false,
                isSuccess: false,
                result: null,
                refreshData: false,
            },
            chantiers: {

                showMessage: false,
                message: "",
                isLoading: false,
                isSuccess: false,
                data: null
            },
            showDelete: false,
            showUpdate: false,


        }
    },
    
    methods: {
        dateFromNow,
        hourFormat,
        async getChantiers() {
            this.chantiers.isLoading = true;
            this.chantiers = await getChantierApi();
        },
        getPointageInfo() {
            this.isLoading = true;
            this.showMessage = false
            const id = this.$route.params.id;
            console.log('id', id)

            fetch({ url: `pointing/${id ?? "-"}`, method: "GET" }).then((data) => {
                this.result = data.content ?? data.data ?? data
                console.log('this.result', this.result)
                this.selectPointage = data.content ?? data.data ?? data
                this.isSuccess = true
            }).catch((err) => {
                this.isSuccess = false;
                this.showMessage = true;
                this.message = `Erreur - ${err}`
                this.result = null
            }).finally(() => {
                this.isLoading = false;
                this.updateData.refresh = false;
                this.updateData.isOpen = false;
                this.updateData.fields = null;
                this.deleteData.isOpen = false;
            })
        },

        deletePointing() {
            this.deleteData.isLoading = true;
            this.deleteData.showMessage = false
            const id = this.$route.params.id;
            fetch({ url: `pointing/${id ?? "-"}`, method: "DELETE" }).then(() => {

                this.$router.push({ name: "PointageListe" })

            }).catch((err) => {
                this.deleteData.isSuccess = false;
                this.deleteData.showMessage = true;
                this.deleteData.message = `Erreur - ${err}`
                this.deleteData.result = null
            }).finally(() => {
                this.deleteData.isLoading = false
                this.deleteData.isOpen = false
            })
        },
        updatePointing() {
            this.updateData.isLoading = true;
            this.updateData.showMessage = false;
            const id = this.$route.params.id;
            let data = {};
            this.updateData.requiredFields.forEach((field) => {
                data[field] = this.updateData.fields[field]
            })
            data["convert_date"]=`${data.date} ${data.hours}`
          
            // if()
            fetch({ url: `pointing/${id ?? "-"}`, data, method: "PUT" }).then(() => {
                // console.log('data', data)
                // this.$router.push({name:"PointageShow",params:{id:data.content.id}})
                this.updateData.isSuccess = true;
                this.updateData.refresh = true;
                
                this.getPointageInfo();


            }).catch((err) => {
                this.updateData.isSuccess = false;
                this.updateData.showMessage = true;
                this.updateData.message = `${err?.response?.data?.message ?? err}`
                this.updateData.result = null
            }).finally(() => {
                this.updateData.isLoading = false
            })
        },
        showUpdateModal() {
            this.updateData.isOpen = true
            this.updateData.fields = this.updateData.fields == null ? this.selectPointage : this.updateData.fields
        }

    },
    watch: {
        "updateData.refresh"(newVal) {
            if (newVal) {
                this.getPointageInfo()
            }
        }
    },
    mounted() {
        this.getPointageInfo();
        this.getChantiers()
    }
}
</script>