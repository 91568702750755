<template>
    <AppLayout>
        <main id="main" class="main">

            <section class="section">
                <BreadCrumb
                    :previous="[
                        { label: 'Accueil', goTo: { name: 'Dashboard' } }]"
                    title="Mon Compte" />
                <!-- End Page Title -->
                <div class="row">
                    <div v-if="isLoading"
                        style="min-height: 50vh;flex-direction: column;align-items: center;justify-content: center;display: flex">
                        <div class="spinner-grow text-warning" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <div class="alert alert-danger  fade show" role="alert" v-else-if="showMessage && !isSuccess">
                        <h4 class="alert-heading">Erreur </h4>
                        <p></p>
                        <hr>
                        <p class="mb-0">{{ message }}</p>
                    </div>

                    <div class="row" v-else-if="result">
                       

                        <div class=" col-md-12">

                            <div class="card">
                                <div class="card-body pt-3">
                                    <!-- Bordered Tabs -->
                                    <ul class="nav nav-tabs nav-tabs-bordered">

                                        <li class="nav-item">
                                            <button class="nav-link active" data-bs-toggle="tab"
                                                data-bs-target="#profile-edit">Profil</button>
                                        </li>
                                        <li class="nav-item">
                                            <button class="nav-link" data-bs-toggle="tab"
                                                data-bs-target="#profile-change-password">Modifier mot de passe</button>
                                        </li>





                                    </ul>
                                    <div class="tab-content pt-2" style="min-height: 450px;">



                                        <div class="tab-pane fade show active profile-edit pt-3" id="profile-edit">

                                            <form class="row g-3" v-if="updateData.fields">
                                                <div class="row mb-3">

                                                    <div class="col-md-5 col-lg-3">
                                                        <img v-if="updateData.fields.genre=='HOMME'" src="@/assets/img/worker3.svg" alt="Profile">
                                                        <img v-else src="@/assets/img/worker1.svg" alt="Profile">

                                                    </div>
                                                    <div class="col-lg-9 col-md-9 ">
                                                        <div class="col-md-12">
                                                    <div class="form-floating">
                                                        <input type="email" class="form-control" id="floatingEmail"
                                                            placeholder="Your Email" required
                                                            v-model="updateData.fields.matricule"
                                                            :readonly="!updateData.isStart"
                                                            :disabled="!updateData.isStart">
                                                        <label for="floatingEmail">Matricule</label>
                                                    </div>
                                                </div>
                                                        <div class="col-md-12 mt-2">
                                                            <div class="form-floating">
                                                                <input type="text" class="form-control"
                                                                    id="floatingName" placeholder="Your Name"
                                                                    :readonly="!updateData.isStart"
                                                                    :disabled="!updateData.isStart"
                                                                    v-model="updateData.fields.firstname" required>
                                                                <label for="floatingName">Nom</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12 mt-2">
                                                            <div class="form-floating">
                                                                <input type="text" class="form-control"
                                                                    id="floatingName" placeholder="Your Name"
                                                                    v-model="updateData.fields.lastname"
                                                                    :readonly="!updateData.isStart"
                                                                    :disabled="!updateData.isStart" required>
                                                                <label for="floatingName">Prenoms</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                

                                                <div class="col-md-6">
                                                    <div class="form-floating">

                                                        <div v-if="departements.isLoading"
                                                            class="spinner-grow text-warning" role="status">
                                                            <span class="visually-hidden">Loading...</span>
                                                        </div>

                                                        <div class="alert alert-danger  fade show" role="alert"
                                                            v-else-if="departements.showMessage && !departements.isSuccess">
                                                            <h4 class="alert-heading">Erreur </h4>
                                                            <p></p>
                                                            <hr>
                                                            <p class="mb-0">{{ departements.message }}</p>
                                                        </div>

                                                        <select class="form-select" v-else id="floatingSelect"
                                                            aria-label="Depar"
                                                            v-model="updateData.fields.departement_id"
                                                            :readonly="!updateData.isStart"
                                                            :disabled="!updateData.isStart">
                                                            <option v-for="(dept, i) in departements.result" :key="i"
                                                                :value="dept.id">{{ dept.name }}</option>

                                                        </select>
                                                        <label for="floatingSelect">Services</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-floating">

                                                        <div v-if="profils.isLoading" class="spinner-grow text-warning"
                                                            role="status">
                                                            <span class="visually-hidden">Loading...</span>
                                                        </div>

                                                        <div class="alert alert-danger  fade show" role="alert"
                                                            v-else-if="profils.showMessage && !profils.isSuccess">
                                                            <h4 class="alert-heading">Erreur </h4>
                                                            <p></p>
                                                            <hr>
                                                            <p class="mb-0">{{ profils.message }}</p>
                                                        </div>

                                                        <select class="form-select" v-else id="floatingSelect"
                                                            aria-label="Depar" v-model="updateData.fields.profil_id"
                                                            :readonly="!updateData.isStart"
                                                            :disabled="!updateData.isStart">
                                                            <option v-for="(dept, i) in profils.result" :key="i"
                                                                :value="dept.id">{{ dept.name }}</option>

                                                        </select>
                                                        <label for="floatingSelect">Poste</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-floating">
                                                        <input type="email" class="form-control" id="floatingEmail"
                                                            placeholder="Your Email" v-model="updateData.fields.email"
                                                            :readonly="!updateData.isStart"
                                                            :disabled="!updateData.isStart" required>
                                                        <label for="floatingEmail">Email</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-floating">
                                                        <select class="form-select" id="floatingEmail"
                                                            aria-label="State" v-model="updateData.fields.genre"
                                                            :readonly="!updateData.isStart"
                                                            :disabled="!updateData.isStart">
                                                            <option>HOMME</option>
                                                            <option>FEMME</option>

                                                        </select>
                                                        <label for="floatingEmail">Genre</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-floating">
                                                        <input type="datetime-local" class="form-control"
                                                            id="floatingEmail" placeholder="Your Email"
                                                            v-model="updateData.fields.date_embauche"
                                                            :readonly="!updateData.isStart"
                                                            :disabled="!updateData.isStart">
                                                        <label for="floatingEmail">Date d'embauche</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-floating">
                                                        <input type="date" class="form-control" id="floatingEmail"
                                                            placeholder="Your Email" v-model="updateData.fields.birth"
                                                            :readonly="!updateData.isStart"
                                                            :disabled="!updateData.isStart">
                                                        <label for="floatingEmail">Date de naissance</label>
                                                    </div>
                                                </div>



                                                <div class="col-md-6">
                                                    <div class="form-floating mb-3">
                                                        <select class="form-select" id="floatingSelect"
                                                            aria-label="State" v-model="updateData.fields.role"
                                                            readonly disabled>
                                                            <option>{{ updateData.fields.role }}</option>

                                                        </select>
                                                        <label for="floatingSelect">Role</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-floating">
                                                        <select class="form-select" id="floatingSelect"
                                                            aria-label="State" v-model="updateData.fields.is_enroll"
                                                            readonly disabled>
                                                            <option value="1">Oui</option>
                                                            <option value="0">Non</option>

                                                        </select>
                                                        <label for="floatingSelect">Est enrolé?</label>
                                                    </div>
                                                </div>


                                                <div class="text-center" v-if="!updateData.isStart"
                                                    @click="startUpdate">
                                                    <button type="button" class="btn btn-secondary">Modifier</button>
                                                </div>
                                                <div class="text-center" v-else >
                                                    <button type="button" class="btn btn-primary" @click="handleUpdate"><i class="bi bi-save  me-1"></i>Enregistrer</button>
                                                    <button type="button" class="btn btn-secondary mx-3"
                                                        @click="resetUpdate">Annuler</button>
                                                </div>
                                            </form>


                                        </div>


                                        <div class="tab-pane fade pt-3" id="profile-change-password">
                                            <div v-if="updatePwd.isLoading" class="spinner-grow text-warning"
                                                            role="status">
                                                            <span class="visually-hidden">Loading...</span>
                                                        </div>
                                            <!-- Change Password Form -->
                                            <form @submit.prevent="handleUpdatePassword" v-else>

                                            

                                                <div class="row mb-3">
                                                    <label for="newPassword"
                                                        class="col-md-5 col-lg-3 col-form-label">Mot de passe
                                                        </label>
                                                    <div class="col-md-7 col-lg-9">
                                                        <input name="newpassword"  v-model="updatePwd.fields.password" type="password" class="form-control"
                                                            id="newPassword">
                                                    </div>
                                                </div>

                                                <div class="row mb-3">
                                                    <label for="renewPassword"
                                                        class="col-md-5 col-lg-3 col-form-label">Confirmer le mot de passe</label>
                                                    <div class="col-md-7 col-lg-9">
                                                        <input name="renewpassword"  v-model="updatePwd.fields.password_confirmation" type="password" class="form-control"
                                                            id="renewPassword">
                                                    </div>
                                                </div>

                                                <div class="text-center">
                                                    <button type="submit" class="btn btn-primary">Changer Mot de passe
                                                        </button>
                                                </div>
                                            </form><!-- End Change Password Form -->

                                        </div>

                                    </div><!-- End Bordered Tabs -->

                                </div>
                            </div>

                        </div>
                      
                    </div>
                </div>
            </section>

        </main>
    </AppLayout>
</template>
<script>
import AppLayout from '@/components/Layouts/AppLayout.vue';
import BreadCrumb from '@/components/Layouts/BreadCrumb.vue';
import fetch from '@/utils/fetch';
import { dateFromNow, notify } from '@/utils/utils';
export default {
    name: "ShowPointeur",
    components: {
        AppLayout,
        BreadCrumb
    },
    data() {
        return {
            cols: [{
                field: "code",
                title: "#",
                type: 'number',
                filter: false
            }, {
                field: "rigth_date",
                title: "Date",
                cellRenderer: (point) => {
                    return dateFromNow(point.rigth_date)
                },

                type: "date",
            },{
                field: "code",
                cellRenderer: ({save_by}) => {
                
                    if (!save_by) {
                        return ""
                    }
                    return save_by.firstname + " " + save_by.lastname
                },
                title: "Enregistre par",
                type: "",
                filter: false
            }, {
                field: "hours",
                title: "Heure de Pointage",
                filter: false,
            }, {
                field: "",
                cellRenderer: (point) => {
                    return `<span class="${point.isLate ? 'badge bg-warning' : 'badge bg-success'}">${point.isLate
                        ? 'Oui' : 'Non'}</span>`;
                },
                title: "En retard ?",
                type: "",
                filter: false,
                sort: false,
            }, {
                field: "actions",
                title: "Actions",
                type: "",
                filter: false,
                sort: false,
            },
            ],
            colsLang: { no_filter: 'Aucun', contain: 'Contiens', not_contain: 'Ne contiens pas', equal: 'Egale', not_equal: 'Différent', start_with: 'Commence par', end_with: 'Termine par', greater_than: 'Supérieur à', greater_than_equal: 'Sup. ou égale à', less_than: 'Inférieur à', less_than_equal: 'Inf. ou égale à', is_null: 'Est null', is_not_null: 'Non null' },
            
            isLoading: false,
            isSuccess: true,
            showMessage: false,
            message: "",
            result: null,
  
            selectPointage: null,
            user: null,
            departements: {
                isLoading: false,
                isSuccess: true,
                showMessage: false,
                message: "",
                result: null,
            },
            profils: {
                isLoading: false,
                isSuccess: true,
                showMessage: false,
                message: "",
                result: null,
            },
            updateData: {
                isLoading: false,
                isSuccess: true,
                showMessage: false,
                message: "",
                result: null,
                fields: null,
                isStart: false,
                requiredFields: ["firstname", "lastname", "departement_id", "profil_id","email","genre","date_embauche","birth","role","matricule"],
            },
            updatePwd: {
                isLoading: false,
                result: null,
                fields: {
                    confirmed:null,
                    password_confirmation:null
                },
            }

        }
    },
    computed: {

    },
    methods: {
        dateFromNow,
        getPointeur() {
            this.isLoading = true;
            this.showMessage = false
            // const id = this.$route.params.id ?? "1";

            fetch({ url: `user`, method: "GET" }).then((data) => {
                this.result = data.content ?? data.data ?? data
                this.updateData.fields = data.content ?? data.data ?? data
                this.isSuccess = true
            }).catch((err) => {
                this.isSuccess = false;
                this.showMessage = true;
                this.message = `Erreur - ${err}`
                this.result = null
            }).finally(() => {
                this.isLoading = false
            })
        },
        getProfils() {
            this.profils.isLoading = true;
            this.profils.showMessage = false;

            fetch({ url: `profil`, method: "GET" }).then((data) => {
                this.profils.result = data.content ?? data.data ?? data

                this.profils.isSuccess = true
            }).catch((err) => {
                this.profils.isSuccess = false;
                this.profils.showMessage = true;
                this.profils.message = `Erreur - ${err}`
                this.profils.result = null
            }).finally(() => {
                this.profils.isLoading = false
            })
        },
        getDepartements() {
            this.departements.isLoading = true;
            this.departements.showMessage = false;

            fetch({ url: `departement`, method: "GET" }).then((data) => {
                this.departements.result = data.content ?? data.data ?? data

                this.departements.isSuccess = true
            }).catch((err) => {
                this.departements.isSuccess = false;
                this.departements.showMessage = true;
                this.departements.message = `Erreur - ${err}`
                this.departements.result = null
            }).finally(() => {
                this.departements.isLoading = false
            })
        },

        onSelectPointage(point) {
            this.selectPointage = point
        },
        startUpdate() {
            this.updateData.isStart = true

        },
        resetUpdate() {
            this.updateData.result = this.result
            this.updateData.isStart = false

        },
        handleUpdate() {
            this.updateData.isLoading = true;
            this.updateData.showMessage = false;
            let data = {};
            this.updateData.requiredFields.forEach((field) => {
                data[field] = this.updateData.fields[field]
            })


            fetch({ url: `users/${this.updateData.fields?.id ?? "-"}`, method: "PUT", data }).then((data) => {
                this.updateData.result = data.content ?? data.data ?? data

                this.updateData.isSuccess = true
               
                this.getPointeur()
                notify({ message: "Modification effectuée", type: "success" })
            }).catch((err) => {
                this.updateData.isSuccess = false;
                this.updateData.showMessage = true;
                let message = `Erreur - ${err}`
                notify({ message, type: "error" })

            }).finally(() => {
                this.updateData.isLoading = false
                this.updateData.isStart = false
                
            })
        },
        handleUpdatePassword() {
            this.updatePwd.isLoading = true;
            this.updatePwd.showMessage = false;
            const data = this.updatePwd.fields
           


            fetch({ url: `users/change_password/${this.updateData.fields?.id ?? "-"}`, method: "PUT", data }).then(() => {
               
                notify({ message: "Modification effectuée", type: "success" })
            }).catch((err) => {
                console.log('err', err)
                
                let message = `${(err?.response?.data?.message ?? err)}`
                notify({ message, type: "error" })

            }).finally(() => {
                this.updatePwd.isLoading = false
                this.updatePwd.fields = {
                    password:null,
                    password_confirmation:null
                }
                
            })
        }
    },
    mounted() {
        this.getPointeur()
        this.getProfils()
        this.getDepartements()
    }
}
</script>