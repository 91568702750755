<template>
    <AppLayout>
        <main id="main" class="main">
            <BreadCrumb :previous="[{ label: 'Accueil', goTo: '/' }]" title="Liste des pointeurs" />
            <!-- End Page Title -->

            <section class="section dashboard">
                <div class="row">
                    <div v-if="isLoading"
                        style="min-height: 50vh;flex-direction: column;align-items: center;justify-content: center;display: flex">


                        <div class="spinner-grow text-warning" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>

                    </div>
                    <div class="alert alert-danger  fade show" role="alert" v-else-if="showMessage">
                        <h4 class="alert-heading">Oups Erreur </h4>
                        <p></p>
                        <hr>
                        <p class="mb-0">{{ message }}</p>
                    </div>


                    <div class="col-lg-12" v-else-if="isSuccess && result">
                        <div class="row">



                            <!-- Recent Sales -->
                            <div class="col-12">
                                <div class="card recent-sales overflow-auto">



                                    <div class="card-body">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <h3 class="card-title">Pointeurs </h3>
                                            <router-link :to="{name:'PointeurCreate'}" type="button" style="height: fit-content;" class="btn btn-primary"
                                                data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top"
                                                ><i class="bi  bi-person-plus-fill me-1"></i>
                                                Ajouter
                                            </router-link>
                                        </div>
                                        <vue3-datatable :rows="result" :loading="deleteIsLoading" :columns="cols" noDataContent="Vous n'avez pas de pointeur enregistré"
                                            paginationInfo="De {0} à {1} sur {2} " :columnFilterLang="colsLang"
                                            :columnFilter="true">
                                            <template #actions="data">
                                                <div style="display: flex;justify-content: start;">


                                                    <router-link
                                                        :to="{ name: 'PointeurShow', params: { id: data.value.id } }"
                                                        type=" button" class="btn btn-secondary mx-1"><i
                                                            class="bi bi-eye"></i></router-link>

                                                    <router-link
                                                        :to="{ name: 'PointeurEdit', params: { id: data.value.id } }"
                                                        type=" button" class="btn btn-primary mx-1"><i
                                                            class="bi bi-pencil"></i></router-link>

                                                    <button @click="showConfirmDelete(data.value)" data-bs-toggle="modal" data-bs-target="#verticalycentered" type=" button"
                                                        class="btn btn-danger mx-1"><i class="bi bi-trash"></i></button>


                                                       
             
                                                </div>

                                            </template>
                                        </vue3-datatable>

                                    </div>

                                </div>
                            </div><!-- End Recent Sales -->


                        </div>
                    </div>


                </div>
            </section>
            <div class="modal fade" id="verticalycentered" tabindex="-1">
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Suppression</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body text-danger" v-if="deleteItem">
                    Voulez-vous vraiment supprimer le pointeur <br> Matricule:{{deleteItem.matricule}} <br><b>{{deleteItem.firstname}} {{deleteItem.lastname}} </b> 
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                      <button type="button" class="btn btn-danger" data-bs-dismiss="modal"  v-if="deleteItem" @click="deletePointeur(deleteItem?.id)">Supprimer</button>
                    </div>
                  </div>
                </div>
              </div>
        </main>
    </AppLayout>
</template>
<script>
import AppLayout from '../../components/Layouts/AppLayout.vue';
import BreadCrumb from '../../components/Layouts/BreadCrumb.vue';
import Vue3Datatable from '@bhplugin/vue3-datatable'
import { dateFromNow, notify } from '../../utils/utils';
import fetch from '@/utils/fetch';


export default {
    name: "ListPointeur",
    components: {
        BreadCrumb,
        AppLayout,
        Vue3Datatable
    },
    data() {
        return {
            cols: [{
                field: "id",
                title: "#",
                type: 'number',
                filter: false
            }, {
                field: "matricule",
                title: "Matricule",
                type: '',
                filter: true
            },
            {
                field: "firstname",
                cellRenderer: (user) => {
                    return `<b>${user.firstname} ${user.lastname} <b>`
                },
                title: "Nom ",
                type: "",
                filter: true
            },
            {
                field: "email",

                title: "Email",
                type: "",
                filter: false
            },
            {
                field: "created_at",
                title: "Embaucher le",
                cellRenderer: (point) => {
                    return dateFromNow(point.rigth_date)
                },

                type: "date",
            }, {
                field: "actions",
                title: "Actions",
                type: "",
                filter: false,
                sort: false,
            },
            ],
            colsLang: { no_filter: 'Aucun', contain: 'Contiens', not_contain: 'Ne contiens pas', equal: 'Egale', not_equal: 'Différent', start_with: 'Commence par', end_with: 'Termine par', greater_than: 'Supérieur à', greater_than_equal: 'Sup. ou égale à', less_than: 'Inférieur à', less_than_equal: 'Inf. ou égale à', is_null: 'Est null', is_not_null: 'Non null' },
            isLoading: false,
            isSuccess: true,
            showMessage: false,
            message: "",
            result: [],
            deleteIsLoading:false,
            deleteItem:null

        }
    },

    methods: {

        dateFromNow,
        getPointeurs() {
            this.isLoading = true;
            this.showMessage = false

            fetch({ url: "users/pointeurs", method: "GET" }).then((data) => {
                this.result = data.content ?? data.data ?? data
                this.isSuccess = true
            }).catch((err) => {
                this.isSuccess = false;
                this.showMessage = true;
                this.message = `Erreur - ${err}`
                this.result = null
            }).finally(() => {
                this.isLoading = false
            })

        },
        showConfirmDelete(item) {
            this.deleteItem= item
           
        },
        deletePointeur(id){
            this.deleteIsLoading = true;
            let notif = {message:"Impossible de supprimer le pointeur,Ressayez",type:"danger"}

            fetch({ url: `users/${id}`, method: "DELETE" }).then(() => {
                notif = {message:"Pointeur supprimé avec succes",type:"success"}
                this.getPointeurs()
            }).catch(() => {
               
            }).finally(() => {
                this.deleteIsLoading = false
                notify(notif)
                
            })

        },
       
    },
    mounted() {
        this.getPointeurs()
    }
}
</script>
